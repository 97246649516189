import { useState, useMemo, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./detail.scss";
import { getProductDetail } from "@/api/product";
import BreadCrumb from "@/components/public/Breadcrumb";
import type { ProductDetail, SkuItem } from "@/models/product.models";
import { Empty, Spin } from "antd";

import DetailCarousel from "./components/detail-carousel";
import DetailInfo from "./components/detail-info";


const Detail = () => {
  const params = useParams();
  const [detail, setDetail] = useState<ProductDetail>();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedSku, setSelectSku] = useState<SkuItem>();
  const productId = useMemo(() => {
    return params.id;
  }, [params]);

  const loadProductDetail = useCallback(async () => {
    setLoading(true);
    const res = await getProductDetail(productId || "");
    if (res.code === "200" && res && res.data) {
      setDetail(res.data);
    } else {
      setError(res.message);
    }
    setLoading(false);
  }, [productId]);
  useEffect(() => {
    loadProductDetail();
  }, [productId]);
 
  return (
    <div className="product-detail-container">
      <div className="product-detail-wrapper  page-wrapper">
        <BreadCrumb
          routes={[
            {
              path: "/product-list",
              title: "商品列表",
            },
            {
              title: "商品详情",
            },
          ]}
        />
        {!loading && error && (
          <div className="empty-con">
            <Empty description={error} image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}

        {loading && (
          <div className="loading-con">
            <Spin size="large" />
          </div>
        )}
        {!loading && detail && (
          <div className="product-detail-main">
            {/* <ImageMagnifier minImg={getResourceUrl(detail.itemPictureDtos[0].name)} maxImg={getResourceUrl(detail.itemPictureDtos[0].name)}/> */}
            <div className="product-detail-info">
              <DetailCarousel data={detail.itemPictureDtos} sku={selectedSku}/>
              <DetailInfo data={detail} setSelectSku={(sku) => {
                  setSelectSku(sku)
              }}/>
            </div>
            <div className="product-description">
              <div className="detail-content">
                <div className="tab-con">
                  <div className="tab-item active">商品详情</div>
                </div>
                <div className="description-detail">
                  {detail.description && (
                    <div
                      className="c"
                      dangerouslySetInnerHTML={{
                        __html: detail.description,
                      }}
                    ></div>
                  )}
                  {!detail.description && <Empty style={{
                    paddingTop: '100px'
                  }} image={Empty.PRESENTED_IMAGE_SIMPLE}  description="暂无详情图"/>}
                </div>
              </div>
              {/* <div className="recommend-content">
                <div className="tab-con">
                  <div className="tab-item">为你推荐</div>
                </div>
              </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Detail;
