import React from "react";
import type { SearchRecordItem } from "@/models/product.models";
import { getResourceUrl } from "@/utils/tool";
import "./product-card.scss";
import { Image } from "antd";
import { useNavigate } from 'react-router-dom';
const ProductCard: React.FC<{ data: SearchRecordItem }> = React.memo(
  ({ data }) => {
    const navigate = useNavigate();
    return (
      <div className="common-product-card" onClick={() => {
        navigate('/product-detail/' + data.id)
      }}>
        <div className="product-img-con">
          <Image
            className="image"
            preview={false}
            src={getResourceUrl('/' + data.pictureName) + '?x-oss-process=image/resize,w_600'}
            placeholder={
              <Image
                preview={false}
                src={getResourceUrl('/' + data.pictureName) + '?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200'}
                width={200}
              />
            }
          />
        </div>
        <div className="product-info-con">
          <div className="name">{data.name}</div>
          <div className="desc">库存 {data.stockNumber}</div>
          <div className="price-con">
            <div className="default">
                <div className="sub">¥</div>
             <div className="price">{data.minPrice}</div>
            </div>
            { data.marketPrice && <div className="market" >¥{data.marketPrice}</div> }
          </div>
          <div className="detail-arraw"><div className="iconfont icon-arrow-right"></div></div>
        </div>
      </div>
    );
  }
);

export default ProductCard;
