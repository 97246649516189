import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { App, Cascader, Checkbox, Form, Input, Modal } from 'antd';
import { Address } from '@/models/user.models'
import { useImmer } from 'use-immer';
import { setAutoFreeze } from 'immer';

import { CityCode } from '@/models/user.models'
import addressApi from '@/api/address';
import { updateAddressHttp, createAddressHttp, addressDetHttp } from '@/api/user';

enum CityEnum {
    'getCitiesHttp' = 1,
    'getCountiesHttp',
    'getTownsHttp'
}
const arr = ['getCitiesHttp', 'getCountiesHttp', 'getTownsHttp']
type K1 = 'getCitiesHttp' | 'getCountiesHttp' | 'getTownsHttp';

let initForm = {
    name: "",
    telephone: "",
    districtId: "",
    provinceName: "",
    provinceId: "",
    cityName: "",
    cityId: "",
    districtName: "",
    townId: "",
    townName: "",
    address: "",
    addressIds: [],
    isDefault: 0
}

const EditCom = forwardRef((props: any, ref) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [options, setOptions] = useState<CityCode[]>([]);
    const { notification } = App.useApp();

    const [basic, updateBasic] = useImmer<{
        editId: string,
        addressItems: CityCode[]
    }>({
        editId: '',
        addressItems: []
    })
    const [editForm] = Form.useForm();
    setAutoFreeze(false)
    const basicRef = useRef(basic)
    basicRef.current = basic


    // 初始化
    const showModal = (id?: string) => {
        updateBasic((draft) => {
            draft.editId = id || ''
        })
        id ? editInit(id) : editForm.setFieldsValue({
            ...initForm
        })
        setIsModalOpen(true);
    };
    /**
     * 
     * 编辑初始化
     * */
    const editInit = (id: string) => {
        addressDetHttp(id).then((res) => {
            if (res.code == '200') {
                const { provinceId, cityId, districtId, townId } = res.data
                editForm.setFieldsValue({
                    ...res.data,
                    addressIds: [provinceId, cityId, districtId, townId]
                })
                updateBasic(draft => {
                    draft.addressItems = [{
                        id: provinceId,
                        fullName: res.data.provinceName,
                        children: []
                    }, {
                        id: cityId,
                        fullName: res.data.cityName,
                        children: []
                    }, {
                        id: districtId,
                        fullName: res.data.districtName,
                        children: []
                    }, {
                        id: townId,
                        fullName: res.data.townName,
                        children: []
                    }]
                })
                // 获取市
                const selectedOptions = options.filter(x => x.id == provinceId);
                dealOptios('getCitiesHttp', selectedOptions[0], false, 1)
            }
        })
    }

    // 获取省份
    const getProvices = async () => {
        await addressApi.getProvicesHttp().then(res => {
            setOptions(res.data.map((x: CityCode) => ({
                ...x,
                isLeaf: false,
                children: []
            })))
        })
    }
    /**
     * 缓存选中的地区对象
     */
    const onChange = (value: (string | number)[], selectedOptions: CityCode[]) => {
        console.log(value, selectedOptions);
        updateBasic(draft => {
            draft.addressItems = selectedOptions
        })
    };
    /**
     * 请求接口处理option
     * name 请求方法
     * targetOption处理的CityCode对象
     * ynLast 是否是最后一级
     * index 只在初始化的时候使用 1,2,3 => 市区街道
     * */

    const dealOptios = (name: string, targetOption: CityCode, ynLast: boolean = false, index?: number) => {
        let key = CityEnum[name as K1]
        addressApi[name as K1](targetOption.id).then(res => {
            targetOption.children = res.data.map((x: CityCode) => ({
                ...x,
                isLeaf: ynLast,
                children: []
            }));
            !res.data.length && (targetOption.isLeaf = true)
            setOptions([...options]);
            if (index && index <= 2 && res.data.length) {
                setTimeout(() => {
                    const temp = targetOption.children.filter((x: CityCode) => x.id == basicRef.current.addressItems[index].id)
                    dealOptios(arr[key], temp[0], !!(key == 3), index + 1)
                }, 10)
            }
        })
    }
    /**
     * 动态加载数据
     * selectedOptions 已选数据
     * */
    const loadData = (selectedOptions: CityCode[]) => {
        let len = selectedOptions.length
        // 当前选择的
        let targetOption = selectedOptions[selectedOptions.length - 1];
        // 获取市
        if (len == 1) {
            dealOptios('getCitiesHttp', targetOption)
        } else if (len == 2) {
            // 区
            dealOptios('getCountiesHttp', targetOption)
        } else if (len == 3) {
            // 街道
            dealOptios('getTownsHttp', targetOption, true)
        }
    };

    // 提交
    const handleOk = () => {
        editForm.validateFields()
            .then((values: any) => {
                const address = {
                    provinceId: basic.addressItems[0].id,
                    provinceName: basic.addressItems[0].fullName,
                    cityId: basic.addressItems[1].id,
                    cityName: basic.addressItems[1].fullName,
                    districtId: basic.addressItems[2]?.id,
                    districtName: basic.addressItems[2]?.fullName,
                    townId: basic.addressItems[3]?.id,
                    townName: basic.addressItems[3]?.fullName,
                    isDefault: Number(values.isDefault)
                }
                basic.editId ? updateAddress(values, address) : createAddress(values, address)
            })
            .catch((errorInfo) => {
                console.log(errorInfo);
            });
    };

    const updateAddress = (values: Object, address: object) => {
        updateAddressHttp({
            id: basic.editId,
            ...values,
            ...address,
        }).then(res => {
            submitDeal(res)
        })
    }
    const createAddress = (values: Object, address: object) => {
        createAddressHttp({
            ...values,
            ...address
        }).then(res => {
            submitDeal(res)
        })
    }
    const submitDeal = (res: {
        message: string;
        code: string;
    }) => {
        if (res.code == '200') {
            notification.success({
                message: 'success',
                description: '已完成',
                duration: 1,
            });
            setIsModalOpen(false);
            props.updateList()
        } else {
            notification.error({
                message: 'error',
                description: res.message,
                duration: 1,
            });
        }
    }

    useImperativeHandle(ref, () => ({
        showModal
    }))
    useEffect(() => {
        getProvices()
    }, [])

    return (
        <>
            <Modal open={isModalOpen} onOk={handleOk} onCancel={() => { setIsModalOpen(false) }} width={800}
                title={
                    <div
                        style={{
                            width: '100%',
                            paddingBottom: '10px',
                            marginBottom: '20px',
                            borderBottom: '1px solid #ddd',
                        }}
                    >
                        {basic.editId ? '编辑地址' : '新增地址'}
                    </div>
                }
                getContainer={false}
            >
                <Form form={editForm}
                    name="editForm"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    autoComplete="off"
                >
                    <Form.Item<Address>
                        label="姓名"
                        name="name"
                        rules={[{ required: true, message: '请输入姓名' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item<Address>
                        label="电话"
                        name="telephone"
                        rules={[{ required: true, message: '请输入电话' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item<Address> label="所在区域"
                        name="addressIds"
                        rules={[{ required: true, message: '请输入详细地址' }]}>
                        <Cascader
                            fieldNames={{ label: 'fullName', value: 'id' }}
                            options={options} loadData={loadData} onChange={onChange}
                        />
                    </Form.Item>

                    <Form.Item<Address>
                        label="详细地址"
                        name="address"
                        rules={[{ required: true, message: '请输入详细地址' }, { min: 5, max: 100, type: 'string', message: '长度在5到100之间' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item<Address> wrapperCol={{ offset: 4, span: 16 }}
                        name="isDefault" valuePropName='checked' >
                        <Checkbox>设为默认收货地址</Checkbox>
                    </Form.Item>

                </Form>
            </Modal>

        </>
    )
});

export default EditCom;


