import React, { useCallback, useEffect } from 'react';
import { Avatar, Card, DatePicker, DatePickerProps, Input, Radio, RadioChangeEvent, Button, App } from 'antd';
import dayjs from 'dayjs';
import info from "./info.module.scss";
import { getUserInfo, saveUserInfo } from '@/api/user';

import { useImmer } from 'use-immer';
import { UserInfo } from '@/models/user.models';


const CardHeadStyle = {
    fontSize: '18px',
    fontWeight: 400,
    color: '#262626'
}
const userInfo: UserInfo = {
    id: '',
    smallIcon: "",
    userName: '',
    nickName: "",
    gender: 0,
    birthday: "",
    shareNumber: ''
}
const InfoApp: React.FC = () => {
    const { notification } = App.useApp();

    //   修改时间
    const [users, updateInfo] = useImmer(userInfo);
    const onSexChange = (e: RadioChangeEvent) => {
        updateInfo(draft => {
            draft.gender = e.target.value
        });
    };
    const onTimeChange: DatePickerProps['onChange'] = (date, dateString) => {
        updateInfo(draft => {
            draft.birthday = dateString
        });
    };
    const onNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        updateInfo(draft => {
            draft.nickName = e.target.value
        });
    };

    /**获取个人信息 */
    const getInfos = useCallback(async () => {
        const res = await getUserInfo();
        if (res.code == "200" && res.data) {
            updateInfo(({
                ...res.data as UserInfo
            }))
        }
    }, []);
    /**保存用户信息*/
    const save = async () => {
        if (!users.birthday) {
            notification.error({
                message: 'error',
                description: '请选择出生日期!',
                duration: 1,
            });
            return
        }
        const res = await saveUserInfo({
            nickName: users.nickName,
            gender: users.gender,
            birthday: users.birthday
        });
        if (res.code == "200") {
            notification.success({
                message: 'success',
                description: '保存成功!',
                duration: 1
            });
        }



    }
    useEffect(() => {
        getInfos();
    }, []);


    return (
        <>
            <Card title="账户信息" bordered={false} style={{ width: '100%', boxShadow: 'none',minHeight:'100%',background:'#fff'}} headStyle={CardHeadStyle} bodyStyle={
                { paddingTop: '30px', paddingLeft: '60px' }
            }>

                <div className={info.card_item}>
                    <span className={info.card_item_name}>用户头像</span>
                    <Avatar size={64} src={users.smallIcon || 'https://pic.jiangxin-food.com/commonIcons/icons/avatar.png'} />
                </div>
                <div className={info.card_item}>
                    <span className={info.card_item_name}>昵称</span>
                    <Input placeholder="请输入昵称" size="large" style={{ width: 200 }} value={users.nickName} onChange={onNameChange} />
                </div>
                <div className={info.card_item}>
                    <span className={info.card_item_name}>性别</span>
                    <Radio.Group onChange={onSexChange} value={users.gender}>
                        <Radio value={1}>男</Radio>
                        <Radio value={0}>女</Radio>
                    </Radio.Group>
                </div>
                <div className={info.card_item}>
                    <span className={info.card_item_name}>出生年月</span>
                    {
                        users.birthday ?
                            <DatePicker size='large' onChange={onTimeChange} value={dayjs(users.birthday, 'YYYY-MM-DD')} />
                            :
                            <DatePicker size='large' onChange={onTimeChange} />
                    }
                </div>

                <Button type="primary" shape="round" size='large' className={info.card_btn} onClick={() => save()}>保存修改</Button>

            </Card>

        </>
    )
};


export default InfoApp;

