import { useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';
const AutoScrollToTop = (props: { children: any; }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0,0);
    }, [location.pathname])
    return props.children;
};

export default AutoScrollToTop;