import { Layout, Space } from "antd";
import { Outlet } from 'react-router-dom';
import LayoutHeader from './header';
import LayoutFooter from './footer';

const { Header, Footer, Sider, Content } = Layout;

const contentStyle: React.CSSProperties = {
    minHeight: 120,
    backgroundColor: "#F4F5F7",
};
const AppLayout: React.FC = (props) => (
    <Space direction="vertical" style={{ width: "100%" }} size={[0, 48]}>
        <Layout>
            <Header style={{
                backgroundColor: '#fff',
                height: '74px',
                borderBottom:'1px solid #eee'
            }}>
                <LayoutHeader />
            </Header>
            <Content style={contentStyle}>
                <Outlet />
            </Content>

            <LayoutFooter />
        </Layout>
    </Space>
);

export default AppLayout;
