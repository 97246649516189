import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store';

interface UserState {
  value: {
    sessionId: string;
    userName: string;
    nickName: string;
    gender: string;
    birthday: string;
    telephone: string;
    smallIcon: string;
  }
}

const initialState: UserState = {
  value: {
    sessionId: "",
    userName: "",
    nickName: "",
    gender: "",
    birthday: "",
    telephone: "",
    smallIcon: "",
  }
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<Partial<UserState['value']>>) => {
      state.value = {
        ...state.value,
        ...action.payload
      }
      if(action.payload.sessionId){
        window.localStorage.setItem('SESSIONID', action.payload.sessionId)
      }
    },

    logout: (state) => {
      state.value = {
        sessionId: "",
        userName: "",
        nickName: "",
        gender: "",
        birthday: "",
        telephone: "",
        smallIcon: "",
      };
    }

  },
})

export const { setUserInfo, logout } = userSlice.actions;


export const sessionId = (state: RootState) => state.user.value.sessionId;

export default userSlice.reducer
