import { useNavigate } from 'react-router-dom';


const useNavigator = () => {
    const navigate = useNavigate();
    const renovationGoTo = (params: {linkValue:string,linkType:number}) => {
        if (String(params.linkValue)) {
            switch (params.linkType) {
                case 0:	//站内跳转
                    navigate(params.linkValue)
                    break;
                case 1:	//url跳转
                    window.open(params.linkValue)
                    break;
                case 2:	//商品
                    navigate("/product-detail/" + params.linkValue)
                    break;
                case 3:	//类目
                    navigate("/product-list?categoryId=" + params.linkValue)
                    break;
                case 4:	//店铺

                    break;
                case 5:	//品牌

                    break;
            }
        }

    }
    return renovationGoTo;
}

export default useNavigator;