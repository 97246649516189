import { useCallback, useEffect, useRef, useState } from 'react';
import { queryMyOrders } from '@/api/order';
import OrderIndex from '@/components/account/order/OrderIndex';
import { useNavigate } from 'react-router-dom';
import { useImmer } from 'use-immer';
import orderCss from './order.module.scss';
const Order = () => {
    const navigate = useNavigate();
    // 订单头部tab选中value
    const [choise, setChoise] = useState('');
    const [isLoadMore, setIsLoadMore] = useState(false);
    const [pageInfo, setPageInfo] = useImmer({
        pageNo: 1,
        pageSize: 5
    })
    // 订单信息
    const [orderInfo, setOrderInfo] = useImmer<{ records: any, totalPage: number }>({ records: [], totalPage: 0 });
    // 订单头部tab
    const orderType = [
        {
            label: "全部订单",
            value: ''
        },
        {
            label: "待付款",
            value: 10
        },
        {
            label: "待发货",
            value: 20
        },
        {
            label: "待收货",
            value: 30
        },
        {
            label: "已完成",
            value: 70
        }
    ];
    // 去购物跳转
    const navigateTo = useCallback(() => {
        navigate("/")
    }, [])
    // 获取我的订单  flag  true 为非第一页
    const myOrders = useCallback(() => {
        queryMyOrders({ pagePO: pageInfo, orderState: choise }).then(res => {
            setIsLoadMore(true)
            if (res.code == "200") {
                if (res.data) {
                    if (pageInfo.pageNo != 1) {
                        const info = JSON.parse(JSON.stringify(orderInfo.records));
                        setOrderInfo(derpet => {
                            derpet.records = info.concat(res.data.records)
                        });
                    } else {
                        setOrderInfo(res.data)
                    }
                } else {
                    setOrderInfo({ records: [], totalPage: 0 })
                }
            }
        })
    }, [choise, orderInfo, pageInfo]);

    /**
     * 
     * 上拉加载
     */

    const containerRef = useRef<any>(null);
    const getBoundingClientRect = useCallback(() => {
        // 元素高度
        const { height } = containerRef.current.getBoundingClientRect();
        // 当前滚动条偏移
        const scrollT = document.documentElement.scrollTop || document.body.scrollTop;
        // 屏幕可视高度
        const screenH = window.screen.height;
        if ((scrollT + screenH) > (height + 220) && isLoadMore && pageInfo.pageNo < orderInfo.totalPage) {
            setIsLoadMore(false)
            setPageInfo(derpet => {
                derpet.pageNo = 2;
            })
        }
    }, [orderInfo])
    useEffect(() => {
        window.addEventListener("scroll", getBoundingClientRect);
        return () => {
            window.removeEventListener("scroll", getBoundingClientRect)
        }
    }, [orderInfo])
    /**
     * 上拉加载结束
     */
    useEffect(() => {
        if (pageInfo.pageNo != 1) {
            setPageInfo(derpet => {
                derpet.pageNo = 1
            });
        } else {
            myOrders()
        }
    }, [choise]);
    useEffect(() => {
        myOrders()
    }, [pageInfo])

    return (
        <div ref={containerRef}>
            <div className={orderCss.header} >
                {
                    orderType.map((item: { label: string, value: number | string }, index: number) => {
                        return <div onClick={() => { setChoise(item.value as string) }} key={index} className={`${orderCss.header_text} ${choise == item.value?orderCss.active:''}`}>
                            {item.label}
                        </div>
                    })
                }
            </div>
            {
                orderInfo.records.length ?
                    orderInfo.records.map((item: any, index: number) => {
                        return <div key={index}>
                            <OrderIndex item={item}></OrderIndex>
                        </div>
                    }) :
                    <div className={orderCss.empty} >
                        <img src={require("../../../assets/images/order/order_no_msg.png")} alt="" />
                        <div onClick={() => navigateTo()} >
                            去购物
                        </div>
                    </div>
            }

        </div>
    )
}

export default Order