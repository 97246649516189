import { request } from '@/utils/request';
import type { ProductSearchOptions, SearchRecordItem, CategoryItem, ProductDetail } from '@/models/product.models'
import type { PaginationResponse } from '@/models/public.models';
export const getGoodsList = (data: any) => {
    return request({
        url: '/member/captcha',
        params: data,
        method: 'GET'
    })
}

/* 获取搜索历史 */
export const getSearchHistory = (data: any) => {
    return request({
        url: '/search/custom/historySearch',
        params: data,
        method: 'GET'
    })
}

/* 获取热门搜索 */
export const getHotWords = (data?: any) => {
    return request<{value: string}[]>({
        url: '/search/custom/hotWords',
        params: data,
        method: 'GET'
    })
}

/* 获取联想词 */
export const getLinkWords = (data: {
    originWord: string;
    storeId: string;
}) => {
    return request<PaginationResponse<SearchRecordItem>>({
        url: '/search/custom/linkWords',
        data: data,
        method: 'POST'
    })
}

/* 获取搜索结果 */
export const getSearchResult = (data: Partial<ProductSearchOptions>) => {
    return request<PaginationResponse<SearchRecordItem>>({
        url: '/search/custom/item',
        data: data,
        method: 'POST'
    })
}

/* 获取商品分类 */
export const getCategoryList = (data: {
    parentId: string | number;
    type: string; // classification
}) => {
    return request<CategoryItem[]>({
        url: '/goods/frontendCategory/getMerchantAppFrontendCategoryList',
        method: 'POST',
        data,
    })
}

/* 获取商品分类广告位图片 */
export const getCategoryAdImage = (id: string) => {
    return request<CategoryItem[]>({
        url: '/goods/frontendCategory/getAD/' + id,
        method: 'POST',
        data: {},
    })
}



/* 获取商品详情 */
export const getProductDetail = (productId: string) => {
    return request<ProductDetail>({
        url: '/app/goods/getGoodsDetail/' + productId,
        method: 'POST',
        data: {},
    })
}

/* 获取第三级商品分类 */
export const getAllThirdFrontendCategoryList = () => {
    return request<CategoryItem[]>({
        url: '/goods/frontendCategory/getAllThirdFrontendCategoryList ',
        method: 'POST',
        data: {},
    })
}
