import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from "redux-persist";

import storageLocation from "redux-persist/lib/storage";

import counterReducer from "./app/counter";
import cartReducer from "./app/cart";
import systemReducer from "./app/system";
import userReducer from "./app/user";
import categoryReducer from "./app/category";

const persistConfig = {
  key: "root",
  storage: storageLocation,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    counter: counterReducer,
    cart: cartReducer,
    system: systemReducer,
    user: userReducer,
    category: categoryReducer,
  })
);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

const persistor = persistStore(store);
export { store, persistor };
