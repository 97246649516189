import React,{ useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from '@/store/hooks'
import { setNavBar, navBarIndex } from "../../store/app/system";
import '../layout.scss';
import CategoryPopup from './category-popup';
let stopHide = false;
const NavBar  = React.memo(() => {
    const navigate = useNavigate();
      const navBar = useAppSelector(navBarIndex);
      const dispatch = useAppDispatch();
      const [showCategoryPopup, setShowCategoryPopup] = useState(false);
      const [ navList ] = useState<{
          path: string,
          title: string,
      } []>([
          {
              path: '/',
              title: '首页',
          },
          {
              path: '/product-list',
              title: '产品分类',
          },
          {
              path: '/about',
              title: '关于我们',
          },
          {
              path: '/contact',
              title: '联系我们',
          },
      ]);
      const location = useLocation();
      useEffect(() => {
        for (let i in navList) {
            if (navList[i].path == location.pathname) {
                dispatch(setNavBar(Number(i)));
                return
            }
        }
    }, [location]);
      return <div className="navbar-con">
          {
              navList.map((v, i) => {
                  return <div key={i} className={`nav-item ${navBar === i ? 'active' : ''}`} onClick={() => {
                      dispatch(setNavBar(i));
                      navigate(navList[i].path);
                      if(v.path == '/product-list') setShowCategoryPopup(false);
                      
                  }} onMouseEnter={() => {
                      if(v.path != '/product-list') return;
                      stopHide = true;
                      setShowCategoryPopup(true)
                  }} onMouseLeave={() => {
                    if(v.path != '/product-list') return;
                    if(stopHide) {
                      stopHide = false;
                    }
                    setTimeout(() => {
                      if(stopHide) return;
                      setShowCategoryPopup(false)
                    }, 300)
                }}>
                      <div className="txt">{v.title}</div>
                      <div className="line"></div>
                  </div>
              })
          }
          <CategoryPopup show={showCategoryPopup} setParentHide={(fast) => {
            setTimeout(() => {
                if(stopHide) return;
                setShowCategoryPopup(false)
            }, fast ? 0 :300)
          }} setParentStopHide={(value: boolean) => {
            stopHide = value;
          }}/>
          
      </div>
  })


export default NavBar;