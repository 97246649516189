import config from '@/config';
import { formtDate } from '@/utils/formDate';
import useNavigator from '@/components/home/useNavigator';
import orderCss from './order.module.scss';



const OrderDetailGoods = (props: { orderInfo: any }) => {
    const orderInfo = props.orderInfo;
    const navigator = useNavigator();
    return (
        <div className={orderCss._detail_top}>
            {/* 订单主信息 */}
            <div className={orderCss._item_header}>
                <div className={orderCss._item_header_id}>订单号：<span>{orderInfo.id}</span></div>
                <p className={orderCss._item_header_time}>{formtDate(orderInfo.orderTime,'yyyy-MM-dd hh:mm:ss')}</p>
            </div>
          

            {/* table表头 */}
            <div className={orderCss._detail_table}>
                <div>商品信息</div>
                <div>数量</div>
                <div>单价</div>
                <div>小计</div>
            </div>
            {
                // 商品信息
                orderInfo.orderGoodsDTOS && orderInfo.orderGoodsDTOS.map((item: any, index: number) => {
                    return <div onClick={()=>navigator({linkType:2,linkValue:item.itemId})} key={index} className={orderCss._item_content}>
                        <div className={orderCss._item_content_goods}>
                            <img src={config.imageServer + item.goodsImage} alt="" />
                            <div className={orderCss._item_content_goods_text}>
                                <div>{item.name}</div>
                                <p>{item.fieldValue}</p>
                            </div>
                        </div>
                        <div className={orderCss._item_content_count}>{item.goodsNum}</div>
                        <div className={orderCss._item_content_payPrice}>¥{item.goodsPrice}</div>
                        <div className={orderCss._item_content_operate}>¥{item.totalPrice}</div>
                    </div>
                })
            }
        </div>
    )
}

export default OrderDetailGoods