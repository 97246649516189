import React, { useCallback } from "react";
import type { MenuProps } from "antd";
import { Dropdown, message } from "antd";

import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { logout, } from '@/store/app/user';
import { setShowLoginPopup, } from '@/store/app/system';
import { clearCartData, } from '@/store/app/cart';
import { logout as logoutApi } from '@/api/user';
const MenuItemStyle = {
  display: "flex",
  alignItems: "center",
  fontSize: "13px",
};
const IconfontStyle = {
  fontSize: "20px",
  marginRight: "4px",
};
const items: MenuProps["items"] = [
  {
    label: (
      <div className="menu-item" style={MenuItemStyle}>
        {" "}
        <div className="iconfont icon-dingdan" style={IconfontStyle}></div>{" "}
        <div className="txt">我的订单</div>
      </div>
    ),
    key: "2",
  },
  {
    label: (
      <div className="menu-item" style={MenuItemStyle}>
        {" "}
        <div
          className="iconfont icon-zhanghaoxinxi"
          style={IconfontStyle}
        ></div>{" "}
        <div className="txt">账号信息</div>
      </div>
    ),
    key: "3",
  },
  {
    label: (
      <div className="menu-item" style={MenuItemStyle}>
        {" "}
        <div
          className="iconfont icon-gerenxinxi"
          style={IconfontStyle}
        ></div>{" "}
        <div className="txt">地址管理</div>
      </div>
    ),
    key: "5",
  },
  {
    label: (
      <div
        className="menu-item"
        style={{
          ...MenuItemStyle,
          color: "red",
        }}
      >
        {" "}
        <div className="iconfont icon-tuichu" style={IconfontStyle}></div>{" "}
        <div className="txt">退出登录</div>
      </div>
    ),
    key: "4",
  },
];
const MenuConfig: { [K: string]: any } = {
//   "1": {
//     path: "/user/info",
//   },
  "2": {
    path: "/user/order",
  },
  "3": {
    path: "/user/info",
  },
  "5": {
    path: "/user/address",
  },
  "4": {
    path: "logout",
  },
};

const App: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const sessionId = useAppSelector(state => state.user.value.sessionId)
  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (MenuConfig[key].path == "logout") {
        doLogout();
    } else {
      navigate(MenuConfig[key].path);
    }
  };
  const doLogout = useCallback(async () => {
    dispatch(logout());
    logoutApi()
    dispatch(clearCartData())
    message.success('退出登录成功');
  }, [])
  return (
    <Dropdown menu={{ items, onClick }} disabled={sessionId ? false : true} placement="bottom">
      <div className="icon-item" onClick={() => {
        if(!sessionId) {
            dispatch(setShowLoginPopup(true))
        }
      }}>
        <div className="iconfont icon-wode-copy"></div>
      </div>
    </Dropdown>
  );
};
export default App;
