import { request } from '@/utils/request';
import type { LoginResponse } from '@/models/user.models';
/* 发送验证码 */
export const sendCaptcha = (data: {
    telephone: string;
    type: string; // 0 登录验证码
}) => {
    return request({
        url: '/member/captcha',
        method: 'POST',
        data,
    })
}

/* 验证码登录 */
export const loginByCode = (data: {
    telephone: string;
    captcha: string; // 0 登录验证码
    inviteCode?: string;
    inviteType?: string;
}) => {
    return request<LoginResponse>({
        url: '/member/quickLogin',
        method: 'POST',
        data,
    })
}

/* 退出登录 */
export const logout = (data?:any) => {
    return request({
        url: '/member/logout',
        method: 'POST',
        data,
    })
}


/* 获取用户信息 */
export const getUserCenter = (data?:any) => {
    return request<LoginResponse>({
        url: '/member/center',
        method: 'POST',
        data,
    })
}


/* 获取用户信息 */
export const getUserInfo = (data?:any) => {
    return request({
        url: '/member/info',
        method: 'POST',
        data,
    })
}

/* 保存用户信息 */
export const saveUserInfo = (data?:any) => {
    return request({
        url: '/member/update',
        method: 'POST',
        data,
    })
}
// 获取用户地址列表
export const getAddressList = ()=>{
    return request({
        url: '/member/address/lists',
        method: 'POST',
    })
}
// 删除地址
export const delAddressItem = ()=>{
    return request({
        url: '/member/address/lists',
        method: 'POST',
    })
}
// 设置默认地址
export const setAddressDefault = (id:string)=>{
    return request({
        url: '/member/address/default/'+id,
        method: 'POST',
    })
}
// 删除地址
export const removeAddress = (id:string)=>{
    return request({
        url: '/member/address/remove/'+id,
        method: 'POST',
    })
}
// 更新地址
export const updateAddressHttp = (data:Object)=>{
    return request({
        url: '/member/address/update',
        method: 'POST',
        data
    })
}
// 新增地址
export const createAddressHttp = (data:Object)=>{
    return request({
        url: '/member/address/create',
        method: 'POST',
        data
    })
}
// 查询地址详情
export const addressDetHttp = (id:string)=>{
    return request({
        url: '/member/address/detail/'+id,
        method: 'POST',
    })
}
