import CheckBox from "@/components/public/CheckBox";
import Stepper from "@/components/public/Stepper";
import { Image, Popconfirm } from "antd";
import type { CartItem as CartItemType } from "@/models/cart.models";
import { getResourceUrl } from "@/utils/tool";
const CartItem: React.FC<{
  data: CartItemType;
  selected: boolean;
  onChange?: (p: { selected: boolean; cartId: string }) => void;
  onEdit?: (p: { data: CartItemType; num: number }) => void;
  onRemove?: (p: string[]) => void;
}> = ({ data, selected, onChange, onEdit, onRemove }) => {
  return (
    <div className="cart-item-con">
      <CheckBox
        checked={selected}
        onChange={(val) => {
          if (selected === val) return;
          onChange &&
            onChange({
              selected: val,
              cartId: data.cartId,
            });
        }}
      />
      <div className="img-con">
        <Image src={getResourceUrl(data.goodsImg)} preview={false} />
      </div>
      <div className="name-spec">
        <div className="name">{data.goodsName}</div>
        <div className="spec">
          {data.saleFieldValue && data.saleFieldValue.length > 3
            ? data.saleFieldValue.split(":")[1]
            : "默认"}
        </div>
      </div>

      <div className="unit-price">
        <span className="sub">¥</span>
        <span className="price">{data.price}</span>
      </div>
      <div className="stepper-con">
        <Stepper
          onChange={(val) => {
            console.log("onChange stepper", val);
            onEdit &&
              onEdit({
                data: data,
                num: val,
              });
          }}
          key={data.num}
          default={parseInt(data.num)}
          max={parseInt(data.storage)}
        />
      </div>

      <div className="unit-price total-price">
        <span className="sub">¥</span>
        <span className="price">
          {(data.price * parseInt(data.num)).toFixed(2)}
        </span>
      </div>

      <div className="edit">
        <Popconfirm
          title="提示"
          description="确认移除商品？"
          onConfirm={() => {
            onRemove && onRemove([data.cartId]);
          }}
          okText="确认"
          cancelText="取消"
        >
          <span className="delete">删除</span>
        </Popconfirm>
      </div>
    </div>
  );
};

export default CartItem;
