import './styles/Stepper.scss';
import { useState, useMemo, useEffect } from 'react';
const Stepper: React.FC<{
  default?: number;
  min?: number;
  max?: number;
  onChange?: (val: number) => void
}> = (props) => {
  const [inputValue, setInputValue] = useState(props.default || 1);
  const canReduce = useMemo(() => {
    const min = props.min || 1
    if(inputValue <= min) {
        return false;
    } 
    return true;
  }, [inputValue])
  const canAdd = useMemo(() => {
    const max = props.max || -1;
    if(max !== -1 && inputValue >=  max) {
        return false;
    } 
    return true;
  }, [inputValue, props.max]);
  const add = (newV?: number) => {
    const max = props.max || -1;
    const newValue = newV !== undefined ? newV : (inputValue + 1)
    if(max !== -1 && newValue >  max) { 
        setInputValue(max)
    } else {
        setInputValue(newValue)
    }
  }
  const reduce = () => {
    const min = props.min || 1;
    const newValue = inputValue - 1
    if( newValue <  min) { 
        setInputValue(min)
    } else {
        setInputValue(newValue)
    }
  }
  useEffect(() => {
    if(inputValue !== props.default && props.onChange) {
        props.onChange(inputValue)
    }
  }, [inputValue])
  return (
    <div className="common-stepper-container">
      <div className={`btn ${!canReduce ? 'dis' : ''}`}  onClick={(() => {
        reduce()
      })}>
        <div className="iconfont icon-reduce-1"></div>
      </div>
      <div className="input-con">
        <input type="number" value={inputValue} onChange={(e) => {
            const newV = e.target.value;
            add(parseInt(newV))
        }}/>
      </div>
      <div className={`btn ${!canAdd ? 'dis' : ''}`} onClick={(() => {
        add()
      })}>
        <div className="iconfont icon-add-quantity"></div>
      </div>
    </div>
  );
};

export default Stepper;
