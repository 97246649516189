import CONFIG from '@/config';

export const getResourceUrl = (value: string) => {
    if(!value) return ;
    if(value.indexOf('http') === 0) {
        return value;
    }
    return CONFIG.resourceHost + (value.indexOf('/') === 0 ? value : '/' + value );

    
}