import { useMemo } from 'react';
import { Breadcrumb } from 'antd';
import { useNavigate } from 'react-router-dom';
const BreadcrumbCommon: React.FC<{ routes: {path?: string; title: string} [] }> = ({ routes }) => {
    const navigate = useNavigate();
    const items = useMemo(() => {
        return routes.map(v => {
            return {
                title: <div style={{
                    cursor: v.path ? 'pointer': ''
                }} onClick={() => {
                    if(v.path) navigate(v.path);
                }}>{v.title}</div>
            }
        })
    }, [routes])
    return <div className="page-wrapper" style={
       {
        paddingTop: '20px',
        paddingBottom: '20px'
       }
    }>
        <Breadcrumb items={items} />
    </div>
}


export default BreadcrumbCommon;