import "./styles/CheckBox.scss";
import React, { useState, useEffect } from "react";
const CheckBox: React.FC<{
  onChange?: (val: boolean) => void;
  checked?: boolean;
  children?: any;
}> = ({ onChange, checked, children }) => {
  const [select, setSelect] = useState(false);
  const [isInit, setIsInit] = useState(true);
  // useEffect(() => {
  //   console.log("select change", select);
  //   // onChange && onChange(select);
  // }, [select]);
  useEffect(() => {
    if (typeof checked == "boolean") {
      setSelect(checked);
    }
  }, [checked]);
  return (
    <div
      className="p-checkbox-container"
      onClick={() => {
        onChange && onChange(!select);
      }}
    >
      <div className={`p-checkbox-con ${!select ? "border" : ""} `}>
        {select && <div className="iconfont icon-xuanze"></div>}
        {/* {
           !select && <div className="border"></div>
        } */}
      </div>
      {children && <div className="has-children">{children}</div>}
    </div>
  );
};

export default CheckBox;
