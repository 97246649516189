import React from "react";
import './leftNav.scss';
import { Link, To, useLocation } from "react-router-dom";
import { UserNavChildren, UserNavs } from '@/models/user.models';

const navList: UserNavs[] = [
    {
        title: '交易管理',
        children: [
            {
                title: '我的订单',
                path: ['/user/order', '/user/orderDetail']
            }
        ]
    },
    {
        title: '管理中心',
        children: [
            {
                title: '账号信息',

                path: ['/user/info']
            },
            {
                title: '地址管理',

                path: ['/user/address']
            },
        ]
    },
]

// 定义一下高阶组件BrandLink
function BrandLink(props: { path: string | To[]; title: string }) {
    const location = useLocation()
    let isActive = props.path.includes(location.pathname);
    return (
        <Link to={props.path[0]} className={isActive ? 'active' : ''}>{props.title}</Link>
    )
}
function UserLink(props: { children: any; }) {
    return (
        props.children.map((t_li: UserNavChildren, index: number) =>
            <li key={index}>
                <BrandLink path={t_li.path} title={t_li.title}></BrandLink>
            </li>
        )
    )
}

const LeftNav: React.FC = () => {


    return (
        <div className="left-nav">
            {
                navList.map((nav: UserNavs) =>
                    <div
                        key={nav.title}
                        className="left-nav_item"
                    >
                        <p className="left-nav_item-title"> {nav.title}</p>
                        <ul>
                            {
                                <UserLink children={nav.children}></UserLink>
                            }
                        </ul>
                    </div>
                )
            }
        </div>
    );
};

export default LeftNav;
