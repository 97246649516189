import React, { useCallback, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./list.scss";
import { useAppSelector } from "@/store/hooks";
import ListFilter from "./components/list-filter";
import ListSort from "./components/list-sort";
import ProductCard from "@/components/product/product-card";
import type { SearchRecordItem } from "@/models/product.models";
import { getSearchResult } from "@/api/product";
import { Spin, Empty, Pagination } from "antd";
import BreadCrumb from "@/components/public/Breadcrumb";

const Page = React.memo(() => {
  // const [filterData, setFilterData] = useState<{ [K: string]: any }>({
  //   pageNo: 1,
  //   pageSize: 10,
  // });
  const filterData = useRef<{ [K: string]: any }>({
    pageNo: 1,
    pageSize: 10,
  });
  const [productList, setProductList] = useState<SearchRecordItem[]>([]);

  const [totalCount, setTotalCount] = useState(0);
  const [isInit, setIsInit] = useState(true);

  const searchKeywords = useAppSelector((state) => state.system.searchKeywords);
  const location = useLocation();

  const loadingRef = useRef(true);
  const reloadList = async () => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    console.log("reloadList", filterData.current);
    const res = await getSearchResult({
      ...filterData.current,
    });
    if (res.code == "200") {
      setProductList(res.data.records);
      setTotalCount(res.data.totalCount);
    }
    loadingRef.current = false;
  };

  useEffect(() => {
    console.log("list init");
    loadingRef.current = false;
    reloadList();
  }, []);
  /* 监听搜索修改 */
  useEffect(() => {
    if (isInit && !searchKeywords) return;
    setIsInit(false);
    console.log("searchKeywords", searchKeywords);
    const newData = {
      ...filterData.current,
      param: searchKeywords,
      originWord: searchKeywords,
    };
    filterData.current = newData;
    reloadList();
  }, [searchKeywords]);
  const onShowSizeChange = useCallback(
    (pageNo: number, pageSize: number) => {
      filterData.current = {
        ...filterData.current,
        pageNo,
        pageSize,
      };
      reloadList();
    },
    [filterData]
  );
  return (
    <div className="product-list-container">
      {location.pathname === "/product-search" && (
        <BreadCrumb
          routes={[
            {
              path: "/",
              title: "首页",
            },
            {
              // path: '/product-search',
              title:
                (searchKeywords ? '"' + searchKeywords + '"' : "") +
                " 搜索结果",
            },
          ]}
        />
      )}
      <ListFilter
        setFilterData={(p) => {
          console.log("filterData", filterData);

          filterData.current = {
            ...filterData.current,
            ...p,
          };
          reloadList();
        }}
      />
      <ListSort
        setFilterData={(p) => {
          console.log("p", p);
          console.log("filterData", filterData);

          filterData.current = {
            ...filterData.current,
            ...p,
          };
          reloadList();
        }}
      />
      <div className="product-list-con page-wrapper">
        <div className="list-main">
          {!loadingRef.current &&
            productList.map((v, i) => {
              return <ProductCard data={v} key={v.id} />;
            })}
        </div>
        {!loadingRef.current && totalCount > 10 && (
          <div className="pagination-con">
            <Pagination
              showSizeChanger
              onChange={onShowSizeChange}
              defaultCurrent={filterData.current.pageNo}
              total={totalCount}
              defaultPageSize={filterData.current.pageSize}
            />
          </div>
        )}
        <div className="con-center">
          {loadingRef.current && (
            <div className="loadin-con">
              <Spin size="large" />
            </div>
          )}
          {!isInit && !loadingRef.current && productList.length == 0 && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </div>
    </div>
  );
});

export default Page;
