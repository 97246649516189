import React, { useEffect, useState } from "react";
import FilterItem from "./filter-item";
import { useAppSelector } from "@/store/hooks";
import { useSearchParams } from 'react-router-dom';
const ListFilter: React.FC<{
  setFilterData?: (p: { [K: string]: any }) => void;
}> = React.memo(({ setFilterData }) => {
  const categoryList = useAppSelector((state) => state.category.value);
  const [searchParams, setSearchParams] = useSearchParams();
  
  
  const [filterDataList, setFilterDateList] = useState<
    {
      label: string;
      param: string;
      select: number;
      list: { text: string; value: string }[];
    }[]
  >([
    {
      label: "分类",
      param: "category",
      select: 0,
      list: [
        {
          text: "全部",
          value: "",
        },
        ...categoryList.map((v) => {
          return {
            text: v.name,
            value: v.id,
          };
        }),
      ],
    },
  ]);
  useEffect(() => {
    const categoryId = searchParams.get('categoryId');
    if(!categoryId) return;
    const defaultIndex = categoryList.findIndex(v => v.id == categoryId);
    console.log('defaultIndex', defaultIndex);
    if(filterDataList[0].select === defaultIndex + 1) {
      return
    }
    filterDataList[0].select = defaultIndex + 1;
    setFilterDateList([
      ...filterDataList,
    ]);
    setFilterData && setFilterData({
      frontCategoryId: categoryId
    })
  }, [searchParams, filterDataList]);
  return (
    <div className="list-filter-con page-wrapper">
      {filterDataList.map((v, i) => {
        return (
          <FilterItem
            {...v}
            key={i}
            setSelected={(index) => {
              setSearchParams({
                'categoryId': ''
              });
              filterDataList[i].select = index;
              const newList = [...filterDataList];
              setFilterDateList(newList);
              setFilterData && setFilterData({
                frontCategoryId: v.list[index].value
              })
            }}
          />
        );
      })}
    </div>
  );
});

export default ListFilter;
