import React, { useState } from 'react';
import { Button, Modal, Image } from 'antd';
import CartImg from '@/assets/images/public/jx-card.png'
const App: React.FC<{ show?: boolean; onChange?: (val: boolean) => void }> = ({ show, onChange }) => {
  const [loading, setLoading] = useState(false);
  const closePopup = () => {
    // setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    //   setOpen(false);
    // }, 3000);
    onChange && onChange(false)

  };


  return (
    <>
      <Modal
        open={show}
        onOk={closePopup}
        onCancel={closePopup}
        footer={[
         
          <Button key="submit" type="primary"  onClick={closePopup}>
            确定
          </Button>
        ]}
      >
        <div className="popup-con" style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin:'0 100px',
            textAlign:'center',
            paddingTop: '30px'
        }}>
            <div className="tips" style={{
                color:'#555',
                lineHeight: '22px',
                fontSize:'14px',
                marginBottom:'10px'
            }}>*结算功能暂未开放，请扫描以下小程序码
进入"江心商城"小程序结算</div>
            <div className="img-con"><Image src={CartImg} preview={false}></Image></div>

        </div>
      </Modal>
    </>
  );
};

export default App;