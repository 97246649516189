import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "@/store/hooks";
import { setCategory } from "../../store/app/category";
import "../layout.scss";
import type { CategoryItem } from "@/models/product.models";
import { getAllThirdFrontendCategoryList, getCategoryList, getCategoryAdImage } from "@/api/product";
import { getResourceUrl } from "@/utils/tool";
import { Image } from 'antd';
const CategoryPopup: React.FC<{ show: boolean; setParentHide: (fast?:boolean) => void; setParentStopHide: (value: boolean) => void }> = React.memo(({ show, setParentHide, setParentStopHide }) => {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const categoryList = useAppSelector(state => state.category.value);
    const [showInner, setShowInner] = useState(false);
    const [hide, setHide] = useState(false);
    // const loadCategoryList = useCallback(async () => {
    //   const categoryRes = await getAllThirdFrontendCategoryList();
    //   dispatch(setCategory(categoryRes.data));
    // }, []);
    const loadCategoryList = useCallback(async () => {
      const categoryRes = await getCategoryList({
        parentId:'0',
        type: 'classification'
      });
      // for(let i = 0; i < categoryRes.data.length ;i ++) {
      //   const res = await getCategoryAdImage(categoryRes.data[i].id)
      // }
      // console.log('categoryRes', categoryRes);
      dispatch(setCategory(categoryRes.data));
    }, []);
    useEffect(() => {
      loadCategoryList();
    }, []);
  
    useEffect(() => {
      if(show) {
        setShowInner(true)
      } else {
        setHide(true);
        setTimeout(() => {
            setShowInner(false)
        }, 100)
      }
    }, [show]);
    // useEffect(() => {
    //     console.log('showInner', showInner)
    // }, [showInner])
    return (
      <div className="">
        {showInner && (
          <div className={`common-category-popup-con ${hide?'hide' : ''}`}>
            <div className={`category-list page-wrapper`} onMouseEnter={() => {
                setParentStopHide(true)
            }} onMouseLeave={() => {
                setParentStopHide(false)
                setParentHide()
            }} onClick={() => {}}>
              {categoryList.slice(0, 5).map((v, i) => {
                return (
                  <div className="category-item" key={i} onClick={() => {
                    setParentStopHide(false)
                    setParentHide(true)
                    navigate('/product-list?categoryId=' + v.id);
                    
                  }}>
                    <div className="img">
                      <Image preview={false} src={v.adSpaceDto && v.adSpaceDto.picUrl ? getResourceUrl(v.adSpaceDto.picUrl) + '?x-oss-process=image/resize,w_200' : ''} alt="" className="i" ></Image>
                    </div>
                    <div className="txt">{v.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  });

export default CategoryPopup;
