import { request } from '@/utils/request';
import type { ShopItem, AddCartParams, EditCartParams } from '@/models/cart.models';

/* 加入购物车 */
export const addCart = (data: AddCartParams) => {
    return request<boolean>({
        url: '/trade/cart/addCart',
        method: 'POST',
        data: data
    })
}


/* 获取购物车列表 */
export const getCartData = () => {
    return request<{
        shops: ShopItem[];
        unusefulGoods: [];
        activityGoods: [];
    }>({
        url: '/trade/cart/getCarts',
        method: 'POST',
        data: {}
    })
}

/* 编辑购物车 */
export const editCart = (data: EditCartParams) => {
    return request<boolean>({
        url: '/trade/cart/editCart',
        method: 'POST',
        data: data
    })
}


/* 删除购物车 */
export const removeCart = (data: string[]) => {
    return request<boolean>({
        url: '/trade/cart/removeCarts',
        method: 'POST',
        data: data
    })
}



