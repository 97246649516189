import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
interface SystemState {
  navBarIndex: number;
  showLoginPopup: boolean;
  showUserAgreement: boolean;
  searchKeywords: string;
  showCartPopup: boolean;
}

const initialState: SystemState = {
  navBarIndex: 0,
  showLoginPopup: false,
  showUserAgreement: false,
  searchKeywords: '',
  showCartPopup: false,
}

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setNavBar: (state, action: PayloadAction<number>) => {
      state.navBarIndex = action.payload
    },

    setShowLoginPopup: (state, action: PayloadAction<boolean>) => {
      state.showLoginPopup = action.payload
    },
    setShowUserAgreement: (state, action: PayloadAction<boolean>) => {
      state.showUserAgreement = action.payload
    },
    setSearchKeywords: (state, action: PayloadAction<string>) => {
      state.searchKeywords = action.payload
    },
    setCartPopup: (state, action: PayloadAction<boolean>) => {
      state.showCartPopup = action.payload
    },
  },
})

export const { setNavBar, setShowLoginPopup, setShowUserAgreement, setSearchKeywords, setCartPopup } = systemSlice.actions;


export const navBarIndex = (state: RootState) => state.system.navBarIndex;
export const showLoginPopup = (state: RootState) => state.system.showLoginPopup;
export const showUserAgreement = (state: RootState) => state.system.showUserAgreement;

export default systemSlice.reducer
