import { useMemo } from 'react';
import Login from '@/assets/images/public/logo.png';
import './layout.scss';
import NavBar from './components/navBar';
// import Search from './components/search';
import { useAppSelector, useAppDispatch } from '@/store/hooks'
import { setNavBar, navBarIndex, setShowLoginPopup } from "../store/app/system";
import { Badge } from 'antd';
import UserMenu from './components/user-menu';
import Search from './components/search';
import { PrimaryColor } from '@/models/constants';
import CartPopOver from './components/cart-popover';
const LayoutHeader = () => {
    const dispatch = useAppDispatch();
    const sessionId = useAppSelector(state => state.user.value.sessionId)
    const cartData = useAppSelector(state => state.cart.shops);
    // const totalCount = useMemo(() => {
    //     let count = 0;
    //     if(!cartData || cartData.length == 0) return count;
    //     cartData.forEach(shop => {
    //         shop.goodsList.forEach(goods => {
    //             count += parseInt(goods.num)
    //         })
    //     })
    //     return count;
    // }, [cartData])

    return <div className="common-header-con page-wrapper">
        <div className="common-header-left">
            <a href={window.location.origin} >
                <div className="logo">
                    <img src={Login} alt="Logo" />
                </div>
            </a>
            <NavBar />
        </div>
        <div className="common-header-right">
            <div className="common-icon-list" >
                <Search />
                <CartPopOver />
                <UserMenu />
            </div>
        </div>
    </div>
}


export default LayoutHeader;