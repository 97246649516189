import config from "@/config";
import orderConfig from '@/utils/orderConfig';
import { useNavigate } from "react-router-dom";
import useNavigator from "@/components/home/useNavigator";
import { formtDate } from "@/utils/formDate";
import orderCss from './order.module.scss';

function OrderIndex(props: { item: any }) {
    const { cmOrderDetailDTOS } = props.item;
    const navigate = useNavigate();
    const navigator = useNavigator();
    const navigateTo = (id:string) => {
        navigate("/user/orderDetail?orderId="+id)
    }
    return (
        <div className={orderCss._item}>
            <div className={orderCss._item_header}>
                <div className={orderCss._item_header_id}>订单号：{props.item.id}</div>
                <div className={orderCss._item_header_time}>{formtDate(props.item.orderTime,'yyyy-MM-dd hh:mm:ss')}</div>
                <p className={orderCss._item_header_status}>{orderConfig.getOrderState(props.item.orderState)}</p>
            </div>
            <div className={orderCss._item_content}>
                <div className={orderCss._item_content_goods}>
                    {
                        cmOrderDetailDTOS.length > 1 ?
                            cmOrderDetailDTOS.map((item: any, index: number) => {
                                return index < 3 && <img onClick={()=>{navigator({linkType:2,linkValue:cmOrderDetailDTOS[0].itemId})}} key={index}  src={config.imageServer + item.goodsImage} alt="" />
                            })
                            :
                            <>
                                <img onClick={()=>{navigator({linkType:2,linkValue:cmOrderDetailDTOS[0].itemId})}}  src={config.imageServer + cmOrderDetailDTOS[0].goodsImage} alt="" />
                                <div className={orderCss._item_content_goods_text}>
                                    <div>{cmOrderDetailDTOS[0].name}</div>
                                    <p>{cmOrderDetailDTOS[0].fieldValue}</p>
                                </div>
                            </>
                    }
                </div>
                <div className={orderCss._item_content_count}>共{cmOrderDetailDTOS.length}件</div>
                
                <div className={orderCss._item_content_payPrice}>¥{props.item.payPrice}</div>
                <div className={orderCss._item_content_operate} onClick={()=>navigateTo(props.item.id)}>查看详情</div>
            </div>
        </div>
    )
}
export default OrderIndex