import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import KeepAlive from 'react-activation'
import NoPage from '@/pages/404';
import Layout from '@/layout';
import AutoScrollToTop from '@/components/public/AutoScrollToTop'
import Login from '@/pages/user/login';
import ProductList from '@/pages/product/list';
import ServiceAbout from '@/pages/service/about';
import ServiceContact from '@/pages/service/contact';
import User from '@/pages/user/center';
import UserInfo from '@/pages/user/account/info'; //账户信息
import Order from '@/pages/user/account/order'; //订单
import UserAddress from '@/pages/user/account/address'; //地址
import ProductDetail from '@/pages/product/detail';
import Cart from '@/pages/product/cart';
import OrderDetail from '@/pages/user/account/OrderDetail';
import { Suspense, lazy } from 'react';
const Home = lazy(() => import('@/pages/home/home'));
const Routers = () => {
    return <BrowserRouter>
        <AutoScrollToTop>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={
                        <KeepAlive autoFreeze={false} when={true} key='home' id='home'>
                            <Suspense fallback={<div></div>}><Home /></Suspense>
                        </KeepAlive>
                    } />
                    <Route path="/login" element={<Login />} />
                    <Route path="/user" element={<User />} >
                        {/* 默认子路由，注意重定向路径最前面要加'/'，并且斜杠后面跟的是带有父路由的完整路径 */}
                        <Route index element={<Navigate to="/user/order" />}></Route>
                        {/* 注意子路由名称前面不要加'/' */}
                        <Route path="info" element={<UserInfo />}></Route>
                        <Route path="order" element={<Order />}></Route>
                        <Route path='orderDetail' element={<OrderDetail />}></Route>
                        <Route path="address" element={<UserAddress />}></Route>
                    </Route>

                    <Route path="/about" element={<ServiceAbout />} />
                    <Route path="/contact" element={<ServiceContact />} />


                    {/* 商品相关 */}
                    <Route path="/product-list" element={<ProductList />} />
                    <Route path="/product-search" element={<ProductList />} />
                    <Route path="/product-detail/:id" element={<ProductDetail />} />
                    <Route path="/cart" element={<Cart />} />

                    <Route path="/404" element={<NoPage />} />
                    <Route path="*" element={<Navigate to="/404" />} />
                </Route>

            </Routes>
        </AutoScrollToTop>
    </BrowserRouter>
}
export default Routers;