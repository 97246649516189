import { request } from '@/utils/request';

// 获取省份
 const getProvicesHttp = ()=>{
    return request({
        url: '/system/district/provices',
        method: 'POST',
    })
}
// 获取市
 const getCitiesHttp = (id:string)=>{
    return request({
        url: '/system/district/cities/'+id,
        method: 'POST',
    })
}
// 获取区
 const getCountiesHttp = (id:string)=>{
    return request({
        url: '/system/district/counties/'+id,
        method: 'POST',
    })
}
// 获取街道
 const getTownsHttp = (id:string)=>{
    return request({
        url: '/system/district/towns/'+id,
        method: 'POST',
    })
}
export default{
    getProvicesHttp,
    getCitiesHttp,
    getCountiesHttp,
    getTownsHttp
}