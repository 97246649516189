import orderCss from './order.module.scss';


const OrderDetailAddress = (props: any) => {
    const orderInfo = props.orderInfo.orderAddressDTO;
    return (
        <div className={orderCss._detail_bottom}>
            <div>
                <span >收件人：</span>
                <p>{orderInfo.name}</p>
            </div>
            <div>
                <span >支付方式：</span>
                <p>微信支付</p>
            </div>
            <div >
                <span >联系方式：</span>
                <p>{orderInfo.telephone}</p>
            </div>
            <div >
                <span >商品合计：</span>
                <p>¥{props.orderInfo.goodsTotal}</p>
            </div>
            <div >
                <span >收件地址：</span>
                <p>{orderInfo.detailAddress}</p>
            </div>
            <div >
                <span >运费：</span>
                <p>+ ¥{props.orderInfo.freightTotal}</p>
            </div>
            <div className={orderCss._detail_total}>
                <span>实付：</span>
                <p>¥{props.orderInfo.payPrice}</p>
            </div>
        </div>
    )
}

export default OrderDetailAddress