import './layout.scss';

const LayoutFooter = () => {
    return <footer className='footer-style'>
        <div className='page-wrapper foot-hot'>
            <div className='foot-flexCenter'>
                <div style={{ color: "#fff", marginRight: "60px" }}>
                    <span style={{ color: "#a9b1b9" }}>售后服务热线</span>
                    <span style={{ fontWeight: "700", marginLeft: "20px" }}>400-032-0070</span>
                </div>
                {/* <div style={{ color: "#fff" }}>
                    <span style={{ color: "#a9b1b9" }}>企业团购热线</span>
                    <span style={{ fontWeight: "700", marginLeft: "20px" }}>400-887-8282</span>
                </div> */}
            </div>

            <div className='foot-flexCenter'>
                <span style={{ color: "#a9b1b9", marginRight: "20px" }}>关注江心味业</span>
                <div className='foot-icon'>
                    <img className='foot-icon-image' src={require("../assets/images/home/gongzhonghao.png")} alt="" />
                    <img className='foot-hove-icon' src={require("../assets/images/home/gongzhonghaohover.jpg")} alt="" />
                </div>
                <div className='foot-icon'>
                    <img className='foot-icon-image' src={require("../assets/images/home/douyin.png")} alt="" />
                    <img className='foot-hove-icon' src={require("../assets/images/home/douyinghover.jpg")} alt="" />
                </div>
                <div className='foot-icon'>
                    <img className='foot-icon-image' src={require("../assets/images/home/xiaochengxu.png")} alt="" />
                    <img className='foot-hove-icon' src={require("../assets/images/home/xiaochenghover.jpg")} alt="" />
                </div>
            </div>
        </div>

        <div className='page-wrapper' style={{ fontSize: "12px", color: "#a9b1b9", margin: "30px auto" }}>Copyright © 2013-2023浙江江心调味食品有限公司&nbsp;&nbsp;|&nbsp;&nbsp; 版权所有 &nbsp;&nbsp;|&nbsp;&nbsp; 网站备案 &nbsp;&nbsp;/&nbsp;&nbsp; 许可证号：浙ICP备18040751号-1 &nbsp;&nbsp;<img style={{ width: "15px", verticalAlign: "middle", marginTop: "-2px" }} src={require("../assets/images/home/foot3.png")} alt="" /> &nbsp;&nbsp;浙公网安备 33032402002344号</div>

        <div className='foot-info page-wrapper'>
            <a href="http://zjamr.zj.gov.cn/"  target="_blank">
                <div className='foot-every-info'>
                    <img src={require("../assets/images/home/foot2.png")} alt="" />
                    <span>浙江工商</span>
                </div>
            </a>
            <a href="http://cyberpolice.mps.gov.cn/wfjb/" target="_blank">
                <div className='foot-every-info'>
                    <img src={require("../assets/images/home/foot3.png")} alt="" />
                    <span>浙江网警网络110</span>
                </div>
            </a>

            {/* <div className='foot-every-info'>
                <img src={require("../assets/images/home/foot4.png")} alt="" />
                <span>支付宝特约商家</span>
            </div> */}
        </div>
    </footer>
}


export default LayoutFooter;