import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "@/store/hooks";
import BreadCrumb from "@/components/public/Breadcrumb";
import "./cart.scss";
import CartItem from "./components/cart-item";
import CheckBox from "@/components/public/CheckBox";
import {
  setSelectedCartId,
  selectAll,
  editCartAction,
  removeCartAction,
  getCartDataAction,
} from "@/store/app/cart";
import type { CartItem as CartItemType } from "@/models/cart.models";
import { Modal, Empty } from 'antd';
import CartPopup from './components/cart-popup';
const CartPage = () => {
  const cartData = useAppSelector((state) => state.cart.shops);
  const navigate = useNavigate();
  const selectedAll = useAppSelector((state) => state.cart.selectedAll);
  const selectedCartId = useAppSelector((state) => state.cart.selectedCartId);
  const dispatch = useAppDispatch();
  const [showCartPopup, setShowCartPopup] = useState(false)

  const onCartSelectChange = useCallback(
    (p: { selected: boolean; cartId: string }) => {
      if (p.selected && selectedCartId.indexOf(p.cartId) != -1) return;
      if (!p.selected && selectedCartId.indexOf(p.cartId) == -1) return;
      dispatch(setSelectedCartId(p.cartId));
    },
    [selectedCartId]
  );

  /* 购物车项编辑 */
  const onCartItemEdit = useCallback(
    async (p: { data: CartItemType; num: number }) => {
      await dispatch(
        editCartAction({
          cartId: p.data.cartId,
          goodsId: p.data.goodsId,
          goodsPrice: p.data.price,
          num: p.num,
        })
      );
      dispatch(getCartDataAction());
    },
    []
  );

  /* 购物车项删除 */
  const onCartItemRemove = useCallback(async (cartIds: string[]) => {
    await dispatch(removeCartAction(cartIds));
    dispatch(getCartDataAction());
  }, []);
  const totalAmount = useMemo(() => {
    let amount = 0;
    if(!cartData || !cartData[0]) return '0.00';
    cartData.forEach((shop) => {
      shop.goodsList.forEach((goods) => {
        if(selectedCartId.indexOf(goods.cartId) != -1) {
          amount += parseInt(goods.num) * goods.price;
        }
       
      });
    });
    amount = parseFloat(amount.toFixed(2)  )
    return amount;
  }, [cartData, selectedCartId]);
  return (
    <div className="cart-page-container">
      <div className="page-main page-wrapper">
        <BreadCrumb
          routes={[
            {
              path: "/",
              title: "首页",
            },
            {
              // path: '/product-search',
              title: "购物车",
            },
          ]}
        />
        <div className="cart-header card-item">
          <div className="left">我的购物车</div>
          <div
            className="to-home"
            onClick={() => {
              navigate("/");
            }}
          >
            <span className="t">继续购物</span>
            <span className="iconfont icon-arrow-right"></span>
          </div>
        </div>

        <div className="cart-list card-item">
          {cartData &&
            cartData[0] &&
            cartData[0].goodsList &&
            cartData[0].goodsList.length > 0 && (
              <div className="list-con">
                <div className="list-head">
                  <div className="head-li checkbox">
                    <CheckBox
                      checked={selectedAll}
                      onChange={(val) => {
                        console.log("selectAll onChange", val);
                        // if(val)
                        dispatch(selectAll(val));
                      }}
                    >
                      <span className="txt">全选</span>
                    </CheckBox>
                  </div>
                  <div className="head-li">
                    <span className="txt">商品信息</span>
                  </div>
                  <div className="head-li">
                    <span className="txt">单价</span>
                  </div>
                  <div className="head-li">
                    <span className="txt">数量</span>
                  </div>
                  <div className="head-li">
                    <span className="txt">小计</span>
                  </div>
                  <div className="head-li">
                    <span className="txt">操作</span>
                  </div>
                </div>
                <div className="list-con-ul">
                  {cartData[0].goodsList.map((item, index) => {
                    return (
                      <CartItem
                        onChange={onCartSelectChange}
                        onRemove={onCartItemRemove}
                        onEdit={onCartItemEdit}
                        selected={selectedCartId.indexOf(item.cartId) != -1}
                        key={item.cartId}
                        data={item}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            {
              (!cartData || !cartData[0]) && <div className="cart-empty">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="购物车中还没有商品"></Empty>
              </div>
            }
        </div>
        {
          cartData && cartData[0] && cartData[0].goodsList && <div className="cart-footer card-item">
          <div className="select-all checkbox">
            <CheckBox
              checked={selectedAll}
              onChange={(val) => {
                console.log("selectAll onChange", val);
                // if(val)
                dispatch(selectAll(val));
              }}
            >
              <span className="txt">全选</span>
            </CheckBox>
            <div className={`delete-mutiple ${selectedCartId.length === 0 ? 'dis' : ''}`} onClick={() => {
              if(selectedCartId.length === 0 ) return;
              Modal.confirm({
                title:'提示',
                content:'确认移除所有选中商品？',
                okButtonProps: {
                  danger: true,
                },
                onOk() {
                  
                  onCartItemRemove(selectedCartId)
                }
              })
            }}>批量删除</div>
          </div>


          <div className="cart-total-con">
            <div className="total-info">
              <div className="total-price">
                <div className="label">合计：</div>
                <div className="price-con">
                  <span className="sub">¥</span>
                  <span className="price">{totalAmount}</span>
                </div>
              </div>
              {/* <div className="promotion">
                <div className="label">优惠：</div>
                <div className="price-con">
                  <span className="sub">¥</span>
                  <span className="price">0.00</span>
                </div>
              </div> */}
            </div>
            <div onClick={() => {
              setShowCartPopup(true)
            }} className={ `submit-btn ${selectedCartId.length === 0 ? 'dis' : ''}`}>去结算({selectedCartId.length})</div>
          </div>

        </div>
        }
      </div>
      <CartPopup show={showCartPopup} onChange={(val) => {
        setShowCartPopup(val)
      }}/>
    </div>
  );
};

export default CartPage;
