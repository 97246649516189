import axios from "axios";
import type { AxiosRequestConfig } from 'axios';
import CONFIG from '@/config/index';
/* 请求拦截 */
axios.interceptors.request.use((config) => {
  return config;
});

/* 响应拦截 */
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("error", error);
    return error;
  }
);
interface RequestConfig  extends AxiosRequestConfig {
    
}
interface Response<T>  {
    data: T;
    message: string;
    code: string;
}
async function baseRequest<T = any>(options: RequestConfig): Promise<Response<T>> {

   return new Promise(async (resolve, reject) => {
     const requestUrl = options.url?.indexOf('http') === -1 ? CONFIG.serverHost + options.url : options.url;
     const sessionId = window.localStorage.getItem('SESSIONID');
     const headers =  {
      ...options.headers,
      'Sessionid': sessionId || '',
     };

     try {
        const res = await axios({
            ...options,
            url: requestUrl,
            headers,
        });
        const responseData = res.data as {
            data: T;
            message: string;
            code: string;
        };
        resolve({
            data: responseData.data,
            message: responseData.message,
            code: responseData.code,
        })
     } catch(error) {
        console.log('response error', error)
        reject(error);

        // resolve({
        //   data: error as T,
        //   message: (error as any).toString(),
        //   code: '500',
        // });
     }
   })
    
}


export const request =  baseRequest;


