import { useEffect, useRef, useState } from 'react';
import type { ProductDetail, SkuItem } from "@/models/product.models";


import { Carousel, Image } from "antd";
import { getResourceUrl } from "@/utils/tool";
const DetailCarousel: React.FC<{ data: ProductDetail["itemPictureDtos"];sku?: SkuItem }> = ({
  data,
  sku,
}) => {
  const carouselRef = useRef<any>();
  const dotsListRef = useRef<any>()
  const [current, setCurrent] = useState(0)
  useEffect(() => {
    console.log('sku', sku);
    carouselRef.current.goTo(0)
  }, [sku])
  return (
    <div className="detail-carousel">
      <div className="carousel-con">
        {/* {
          sku && 
        } */}
        <Carousel dots={false} ref={carouselRef} beforeChange={(e, next) => {
            setCurrent(next)
            if(next >= 4 && e < next) {
                dotsListRef.current.scrollLeft = next * 110
            } else {
                dotsListRef.current.scrollLeft = next * 110
                
            }

        }}>
          {
            sku && sku.picUrl && <div className="carousel-item" key="sku">
            <div className="img-con">
              <Image src={getResourceUrl(sku?.picUrl)} />
            </div>
          </div>
          }
          {data.map((v, i) => {
            return (
              <div className="carousel-item" key={i}>
                <div className="img-con">
                  <Image src={getResourceUrl(v.name)} />
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
      <div className="dots-con">
        <div className="arrow-icon" onClick={() => {
            carouselRef.current.prev();
        }}>
          <div className="iconfont icon-arrow-left-copy"></div>
        </div>
        <div className="carousel-dots-list" ref={dotsListRef}>
          <div className="list-con" >
         
          {data.map((v, i) => {
            return (
              <div className={`carousel-dots-item ${(i+1) === current ? "active" : ''}`} key={(i+1)} onClick={() => {
                carouselRef.current.goTo(i+1)
            }}>
                 <Image
                    src={
                      getResourceUrl(v.name) +
                      "?x-oss-process=image/resize,w_300"
                    }
                    preview={false}
                  />
              </div>
            );
          })}
          </div>
        </div>
        <div className="arrow-icon"  onClick={() => {
            carouselRef.current.next()
        }}>
          <div className="iconfont icon-arrow-right"></div>
        </div>
      </div>
    </div>
  );
};

export default DetailCarousel;
