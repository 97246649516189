import { useSearchParams } from "react-router-dom"
import { queryOrderDetail, getExpressageInfo } from '@/api/order'
import { useCallback, useEffect, useState } from "react";
import OrderDetailGoods from "@/components/account/order/OrderDetailGoods";
import OrderDetailAddress from "@/components/account/order/OrderDetailAddress";


import { Card } from "antd";


const OrderDetail = () => {
    const [params] = useSearchParams();
    const [orderInfo, setOrderInfo] = useState({ orderGoodsDTOS: [], id: "", orderTime: "" });
    const [expressage, setExpressage] = useState([{ comName: "", nu: "" }])
    // 获取订单详情
    const queryOrder = useCallback(() => {
        queryOrderDetail(params.get("orderId")!).then(res => {
            if (res.code == "200") {
                setOrderInfo(res.data);
            }
        });
    }, [params.get("orderId")]);

    // 获取物流信息
    const expressageInfo = useCallback(() => {
        getExpressageInfo(params.get("orderId")!).then(res => {
            if (res.code == "200") {
                setExpressage(res.data)
            }
        })
    }, [params.get("orderId")]);

    useEffect(() => {
        queryOrder();
        expressageInfo()
    }, [params.get("orderId")])
    return (
        <div >
            <Card title="物流状态" bordered={false} style={{ width: '100%', boxShadow: 'none', background: '#fff', marginBottom: '20px' }} headStyle={{
                fontSize: '16px',
                fontWeight: 400,
                color: '#262626',
                paddingLeft: '30px'
            }} bodyStyle={
                { paddingTop: '20px', paddingLeft: '30px', background: '#F8FAFC' }
            }>
                    {

                        expressage.length ?
                            <>
                                <div>物流公司：{expressage[0].comName}</div>
                                <div>快递单号：{expressage[0].nu}</div>
                            </> :
                            <div style={{color: '#6E6E6E'}}>暂无物流状态</div>
                    }

            </Card>


            {orderInfo.id && <OrderDetailGoods orderInfo={orderInfo}></OrderDetailGoods>}

            {orderInfo.id && <OrderDetailAddress orderInfo={orderInfo}></OrderDetailAddress>}
        </div>
    )
}

export default OrderDetail