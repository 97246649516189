import React, { useState, useCallback, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { setSearchKeywords } from "@/store/app/system";
import "../layout.scss";
import { Input, Spin, Empty } from "antd";
import { getHotWords, getLinkWords } from "@/api/product";
import { useLocation, useNavigate } from "react-router-dom";
let searchTimer: any;
const Search = React.memo(() => {
  const [showSearch, setShowSearch] = useState(false);
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [hotWords, setHotWords] = useState<{ value: string; id?: string }[]>([
    {
      value: "原酿黄酒",
    },
    {
      value: "酱油醋酸爽型",
    },
    {
      value: "双缸酱油特级",
    },
    {
      value: "味极鲜",
    },
    {
      value: "原酿米醋",
    },
    {
      value: "花椒米醋",
    },
  ]);
  const [linkWords, setLinkWords] = useState<{ value: string; id?: string }[]>(
    []
  );
  const searchKeywords = useAppSelector(state => state.system.searchKeywords);
  const [searchLoading, setSearchLoading] = useState(false);
  const [showRecommend, setShowRecommend] = useState(true);
  const [hide, setHide] = useState(false);
  const [inputValue, setInputValue] = useState(searchKeywords);
  const location = useLocation();
  const navigate = useNavigate();
  /* 加载热搜 */
  const loadHotWords = useCallback(async () => {
    const res = await getHotWords();
    if (res.code == "200" && res.data.length > 0) {
      setHotWords(res.data);
    }
  }, []);
  /* 获取搜索联想 */
  const loadLinkWords = useCallback(async (keywords: string) => {
    if (searchTimer) clearTimeout(searchTimer);

    searchTimer = setTimeout(async () => {
      if (keywords.length == 0) {
        return;
      }
      setSearchLoading(true);
      const res = await getLinkWords({
        storeId: "",
        originWord: keywords,
      });
      setSearchLoading(false);
      if (res.code == "200") {
        if (res.data.records.length > 0) {
          setLinkWords(
            res.data.records.map((v) => {
              return {
                value: v.name,
                id: v.id,
              };
            })
          );
          //   setLinkWords([])
        } else {
        }
      }
      searchTimer = undefined;
    }, 300);
  }, []);

  /* 搜索 */
  const search = useCallback((keywords: string) => {
    console.log('location.pathname', location.pathname)
    if (location.pathname != "/product-search") {
      navigate("/product-search");
    }
    dispatch(setSearchKeywords(keywords));
    setInputValue(keywords)
    setShowRecommend(false)
  }, [location]);
  useEffect(() => {
    loadHotWords();
    // dispatch(setSearchKeywords(''));
    setInputValue('')
  }, []);
  return (
    <div className="common-search-con">
      {!showSearch && (
        <div
          className="icon-item"
          onClick={() => {
            setShowSearch(true);
          }}
        >
          <div className="iconfont icon-sousuo"></div>
        </div>
      )}

      {showSearch && (
        <div className={`search-popup ${hide ? "hide" : ""}`}>
          <div className="search-popup-header">
            <div className="input-con">
              <div className="iconfont icon-sousuo"></div>
              <Input
                onInput={(e: any) => {
                  const value = e.target.value;
                  setInputValue(value);
                  setSearchValue(value.trim());
                  if (value && value.trim().length > 0) {
                    loadLinkWords(value.trim());
                  } else {
                    setLinkWords([]);
                  }
                }}
                value={inputValue}
                onFocus={() => {
                  setShowRecommend(true)
                }}
                onBlur={() => {
                  // setShowRecommend(false)
                }}
                onPressEnter={() => {
                  search(inputValue)
                }}
                // onConfirm={() => {

                // }}
                className="input"
                autoFocus
                placeholder="请输入您要搜索的商品"
                bordered={false}
              />
            </div>

            <div
              className="close-icon"
              onClick={() => {
                setHide(true);
                setTimeout(() => {
                  setShowSearch(false);
                  setHide(false);
                }, 100);
              }}
            >
              <div className="iconfont icon-close2"></div>
            </div>
          </div>
          {showRecommend && (
            <div className="search-popup-result">
              <div className="tip">
                {linkWords.length > 0 ? "猜你想搜" : "大家都在搜"}
              </div>
              {searchValue.length == 0 && (
                <div className="list">
                  {hotWords.slice(0, 8).map((v, i) => {
                    return (
                      <div
                        className="item"
                        key={i}
                        onClick={() => {
                          search(v.value);
                        }}
                      >
                        {v.value}
                      </div>
                    );
                  })}
                </div>
              )}

              {searchValue.length > 0 && (
                <div className="list">
                  {!searchLoading &&
                    linkWords.length > 0 &&
                    linkWords.slice(0, 8).map((v, i) => {
                      return (
                        <div
                          className="item"
                          key={i}
                          onClick={() => {
                            search(v.value);
                          }}
                        >
                          {v.value}
                        </div>
                      );
                    })}
                  {!searchLoading && linkWords.length == 0 && (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
                  {searchLoading && (
                    <div
                      className="loading-con"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Spin />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default Search;
