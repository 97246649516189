import { request } from '@/utils/request';
// 获取我的订单列表
export const queryMyOrders = (data: any) => {
    return request({
        url: '/trade/customer/queryMyOrders',
        data: data,
        method: 'POST'
    })
};
// 根据id获取订单详情
export const queryOrderDetail = (id:string) =>{
    return request({
        url: '/trade/customer/queryOrderDetail/'+id,
        data: {},
        method: 'POST'
    })
}
// 根据id获取物流状态
export const getExpressageInfo = (id:string) =>{
    return request({
        url: '/transport/expressage/getExpressageInfo',
        data: {orderId:id},
        method: 'POST'
    })
}
