import React from 'react';
import { Layout } from 'antd';
import LeftNav from '@/components/account/leftNav';
import { Outlet } from 'react-router-dom';
import moduleCss from "./user.module.scss";


const { Sider } = Layout;


const User: React.FC = () => (
    <Layout className={moduleCss.center}>
        <Sider className={moduleCss.left}>
            <LeftNav></LeftNav>
        </Sider>
        <Layout className={moduleCss.content}>
            <Outlet></Outlet>
        </Layout>
    </Layout>
);

export default User;