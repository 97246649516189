import { useState, useMemo, useCallback, useEffect } from "react";
import type { ProductDetail , SkuItem } from "@/models/product.models";
import type { AddCartParams } from "@/models/cart.models";
import Stepper from '@/components/public/Stepper';
import { message } from 'antd';
import { getCartDataAction, addCartAction } from '@/store/app/cart';
import { addCart } from '@/api/cart';
import { setShowLoginPopup } from '@/store/app/system';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getResourceUrl } from "@/utils/tool";

const DetailInfo: React.FC<{ data: ProductDetail; setSelectSku?: (p: SkuItem) => void}> = ({ data, setSelectSku }) => {
  const [currentAttrValueId, setCurrentAttrValueId] = useState("");
  const [quantity, setQuantity] = useState(1);
  const dispatch = useAppDispatch();
  const sessionId = useAppSelector(state => state.user.value.sessionId);
  const [tempCartData, setTempCartData] = useState<AddCartParams>();
  const skuData = useMemo(() => {
    const find = data.skuList.find(
      (v) =>
        v.attrValueDTOList &&
        v.attrValueDTOList[0] &&
        v.attrValueDTOList[0].attrValueId === currentAttrValueId
    );
    if (find) {
      const findAttr = data.attrSpecList[0].valueDTOList.find(v => v.attrValueId == currentAttrValueId)
      if(findAttr) {
        find.picUrl = findAttr.picUrl;
        setSelectSku && setSelectSku(find );
      }
      return find;
    }
    else {
     
      if (data.skuList[0].attrValueDTOList) {
        setCurrentAttrValueId(data.skuList[0].attrValueDTOList[0].attrValueId);
      }

      return data.skuList[0];
    }
   
  }, [currentAttrValueId]);
  const skuPromotion = useMemo(() => {
    const promotionList = data.skuPromotionList;
    if(promotionList && promotionList.length > 0) {
      const findCurrent = promotionList.find(v => v.skuId == skuData.skuId)
      if(findCurrent && findCurrent.freightPromotion) {
        return JSON.parse(findCurrent.freightPromotion.name)
      }
    }
    return '';
    
  }, [skuData])

  const attrValueMap = useMemo(() => {
    let _return: {
      [K:string]: {
        stockNumber: number;
        status: number;
      }
    } = {};

    data.skuList?.forEach(v => {
      const attrValueId = v.attrValueDTOList[0].attrValueId;
      // const findAttr = data.attrSpecList[0].valueDTOList.find(v => v.attrValueId == attrValueId)

      _return[attrValueId] = {
        stockNumber: parseInt((v.stockNumber || 0) + ''),
        status: v.status,
      }
    })
    return _return;
   
  }, [data.skuList, data.attrSpecList]);
  const serviceList = ["正品保障", "极速发货", "售后无忧", "闪电退款"];
   /* 加入购物车 */
   const doAddToCart = useCallback(async () => {
    const cartParams = {
      goodsId: skuData.skuId,
      goodsPrice: skuData.price,
      num: quantity,
    }
    if(!sessionId) {
      // setTempCartData(cartParams);
      return dispatch(setShowLoginPopup(true))
    }
    await dispatch(addCartAction(cartParams))
      dispatch(getCartDataAction())
  }, [sessionId, skuData, quantity]);
  
 
  return (
    <div className="detail-info">
      <div className="detail-info-name">{data.name}</div>
      <div className="detail-info-list">
        <div className="info-item">
          <div className="label">活动价</div>
          <div className="value price-con">
            <div className="sub">¥</div>
            <div className="price">{skuData.price}</div>
            {data.marketPrice && <div className="market" >¥{data.marketPrice}</div>}
          </div>
        </div>

        <div className="info-item">
          <div className="label">服务</div>
          <div className="value service-list">
            {serviceList.map((v, i) => {
              return (
                <div className="service-item" key={i}>
                  <div className="iconfont icon-xuanzhong"></div>
                  <div className="txt">{v}</div>
                </div>
              );
            })}
          </div>
        </div>
        
        <div className="info-item">
          <div className="label">运费</div>
          <div className="value freight">
            <div className="sub">¥</div>
            <div className="price">{data.freight.amount}</div>{" "}
            {skuPromotion && <div className="promotion">（{skuPromotion}）</div>}
          </div>
        </div>
        
        <div className="info-item">
          <div className="label">规格</div>
          <div className="value spec-list">
            {data.attrSpecList &&
              data.attrSpecList[0] &&
              data.attrSpecList[0].valueDTOList.map((v, i) => {
                return (
                  <div
                    className={`spec-item ${
                      v.attrValueId === currentAttrValueId ? "active" : ""
                    } ${attrValueMap?.[v.attrValueId].stockNumber <= 0 || attrValueMap?.[v.attrValueId].status == 0? 'disabled' : ''}`}
                    key={i}
                    onClick={() => {
                      if(attrValueMap?.[v.attrValueId].stockNumber <= 0 || attrValueMap?.[v.attrValueId].status == 0) return;
                      setCurrentAttrValueId(v.attrValueId);
                    }}
                  >
                    {v.picUrl && <div className="img" ><img src={ v.picUrl ? getResourceUrl(v.picUrl +  "?x-oss-process=image/resize,w_200") : ''} alt="" /></div>}
                    <div className="txt">{v.attrValue}</div>
                  </div>
                );
              })}

            {data.attrSpecList && !data.attrSpecList[0] && (
              <div className={`spec-item active`} onClick={() => {}}>
                默认
              </div>
            )}
          </div>
        </div>
        <div className="info-item">
          <div className="label">数量</div>
          <div className="value stepper">
                <Stepper max={9} onChange={(val) => {
                    setQuantity(val)
                }}/>
          </div>
        </div>
        <div className="info-item">
            <div className="value">
               {data.status === 2 && <div className="primary-button" onClick={doAddToCart}>加入购物车</div>}
               {data.status !== 2 && <div className="primary-button dis">商品已下架</div>}
            </div>
        </div>
      </div>
    </div>
  );
};

export default DetailInfo;
