import React, { useCallback, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "@/store/hooks";
import {
  showLoginPopup,
  setShowLoginPopup,
  setShowUserAgreement,
} from "../../store/app/system";
import { MobileOutlined, SafetyOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Space, message } from "antd";
import { PrimaryColor } from "@/models/constants";
import UserAgreement from "@/components/user/userAgreement";
import { sendCaptcha, loginByCode } from "@/api/user";
import { mobileValidate } from "@/utils/validate";
import { setUserInfo } from "@/store/app/user";
import { getCartDataAction } from "@/store/app/cart";

let _timer: any;
const App: React.FC = () => {
  const [showMobileWarning, setShowMobileWarning] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [countDown, setCountDown] = useState(-1);
  const showPopup = useAppSelector(showLoginPopup);
  const [form] = Form.useForm();
  const sessionId = useAppSelector(state => state.user.value.sessionId);
  const dispatch = useAppDispatch();

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    dispatch(setShowLoginPopup(false));
  };

  /* 发送验证码 */
  const doSendCaptcha = useCallback(async (mobile: string) => {
    if (!mobileValidate(mobile)) {
      setShowMobileWarning(true);
      return;
    }
    setShowMobileWarning(false);
    setSendLoading(true)
    const res = await sendCaptcha({
      telephone: mobile,
      type: "0",
    });
    setSendLoading(false)
    if (res.code != "200") {
      Modal.warning({
        title: res.message,
      });
    } else {
      startCountDown();
    }
  }, []);

  /* 登录 */
  const doLoginByCode = useCallback(
    async (options: { mobile: string; code: string }) => {
      setLoginLoading(true)
      const res = await loginByCode({
        telephone: options.mobile,
        captcha: options.code,
        inviteCode: '',
        inviteType: '',
      });
      setLoginLoading(false)
      if (res.code != "200") {
        Modal.warning({
          title: res.message,
        });
      } else {
        dispatch(setUserInfo({
          sessionId: res.data.sessionId,
          userName: res.data.userName,
          nickName: res.data.nickName,
          gender: res.data.gender,
          birthday: res.data.birthday,
          telephone: res.data.telephone,
          smallIcon: res.data.smallIcon,
        }))
        resetForm()
        dispatch(setShowLoginPopup(false))

        message.success('登录成功');
        dispatch(getCartDataAction())
      }
    },
    []
  );
  const startCountDown = useCallback(() => {
    if (_timer) clearInterval(_timer);
    let secends = 60,
      count = 0;
    setCountDown(60);
    _timer = setInterval(() => {
      count++;
      setCountDown(secends - count);
      if (count >= 60) {
        if (_timer) clearInterval(_timer);
        setCountDown(-1);
      }
    }, 1000);
  }, []);
  const resetForm = useCallback(() => {
    if (_timer) clearInterval(_timer);
    setCountDown(-1);
    form.setFieldsValue({
      mobile: '',
      code: ''
    })
  }, [])
 
  return (
    <>
      {/* <Button type="primary" onClick={showModal}>
        Open Modal with customized button props
      </Button> */}
      <UserAgreement />
      <Modal
        title="手机号登录"
        width={380}
        style={
          {
            // borderRadius: 20
          }
        }
        styles={{
          header: {
            textAlign: "center",
          },
          content: {
            borderRadius: 10,
          },

          // wrapper: {
          //   width: '300px',
          // },
        }}
        open={showPopup}
        footer={null}
        onCancel={handleCancel}
        maskClosable={false}
      >
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={(e) => {
            console.log("e", e);
          }}
          style={{
            paddingTop: "20px",
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
          form={form}
        >
          <Form.Item
            name="mobile"
            validateStatus={showMobileWarning ? "error" : ""}
            help={showMobileWarning ? "手机号码格式错误" : ""}
            rules={[{ required: true, message: "手机号码不能为空" }]}
          >
            <Input
              prefix={<MobileOutlined />}
              placeholder="请输入手机号"
              size="large"
              type="number"
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Form.Item
                noStyle
                name="code"
                rules={[{ required: true, message: "验证码不能为空" }]}
              >
                <Input
                  prefix={<SafetyOutlined />}
                  type="number"
                  size="large"
                  placeholder="输入验证码"
                />
              </Form.Item>
              <Button
                onClick={() => {
                  if(sendLoading) return;
                  const mobile = form.getFieldValue("mobile");
                  doSendCaptcha(mobile);
                }}
                loading={sendLoading}
                block
                size="large"
                disabled={countDown !== -1}
                style={{ borderRadius: 30, marginLeft: 10, width: 150 }}
                type="primary"
              >
                {countDown == -1 ? "发送验证码" : countDown + "s后可重新发送"}
              </Button>
            </Space>
          </Form.Item>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              onClick={async () => {

                try {
                  await form.validateFields();
                  const formData = form.getFieldsValue();
                  doLoginByCode(formData);
                } catch (errorInfo) {
                  console.log('Failed:', errorInfo);
                }
              
              }}
              block
              size="large"
              htmlType="submit"
              style={{ borderRadius: 30, marginLeft: 10 }}
              type="primary"
              loading={loginLoading}
            >
              手机号码快捷登录
            </Button>
          </div>
          <div
            className="tips"
            style={{
              textAlign: "center",
              fontSize: 12,
              marginTop: 10,
            }}
          >
            登录即表明同意江心味业{" "}
            <span
              style={{
                color: PrimaryColor,
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(setShowUserAgreement(true));
              }}
            >
              《用户协议》
            </span>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default App;
