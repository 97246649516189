import { useLayoutEffect } from 'react';
import { useAppDispatch } from "@/store/hooks";
import { setSearchKeywords, setShowLoginPopup } from "@/store/app/system";
import { getCartDataAction } from '@/store/app/cart';

const InitApp = (props: { children: any; }) => {
    const dispatch = useAppDispatch();

    useLayoutEffect(() => {
      console.log('App Init');
      dispatch(setSearchKeywords(''));
      dispatch(setShowLoginPopup(false));
      dispatch(getCartDataAction());
    }, [])
   
    return props.children;
};

export default InitApp;