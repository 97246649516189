import { App, Button, Card, Col, Row } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useEffect, useState, useRef } from 'react';

import { getAddressList, setAddressDefault, removeAddress } from "@/api/user";
import addressStyle from "./address.module.scss";
import EditCom from '@/components/account/address/edit';

import { Address } from '@/models/user.models';
import { AnyObject } from 'antd/es/_util/type';
const CardHeadStyle = {
    fontSize: '18px',
    fontWeight: 400,
    color: '#262626'
}


const AddressEle: React.FC = () => {
    const { message, modal } = App.useApp();
    const [list, setList] = useState([])
    const EditComRef = useRef<AnyObject>(null);

    // 获取用户地址列表
    const getList = () => {
        getAddressList().then(res => {
            if (res.code == '200') {
                res.data = res.data?.map((x: Address) => ({
                    ...x,
                    addressText: (x?.provinceName || '') + x?.cityName + x.districtName + x.townName + x.address
                })) || []
                setList(res.data)
            } else {
                message.error(res.message)
            }
        })
    }
    // 确认删除
    const del = (id: string) => {
        modal.confirm({
            title: '提示信息',
            icon: <ExclamationCircleOutlined />,
            content: '确认删除此地址吗？',
            okText: '删除',
            cancelText: '取消',
            onOk: (close) => {
                removeAddress(id).then(res => {
                    if (res.code == '200') {
                        close()
                        getList();
                    }
                })
            }
        });
    };
    // 设置默认地址
    const setDefault = (id: string) => {
        setAddressDefault(id).then(res => {
            if (res.code == '200') {
                getList();
            }
        })
    };
    // 编辑地址
    const handleClick = (id?: string) => {
        EditComRef.current?.showModal(id)
    }

    useEffect(() => {
        getList();
    }, []);

    return (
        <>
            <Card title="地址管理" bordered={false} style={{ width: '100%', boxShadow: 'none',minHeight:'100%',background:'#fff' }} headStyle={CardHeadStyle} bodyStyle={
                { paddingTop: '30px', paddingLeft: '30px' }
            }>
                <Row gutter={16}>
                    <Col span={8} >
                        <div className={`${addressStyle._item} ${addressStyle.add}`} onClick={() => { handleClick() }}>
                            <PlusOutlined />
                            <p>新建地址</p>
                        </div>
                    </Col>
                    {
                        list.map((x: Address) => (
                            <Col span={8} key={x.id}>
                                <div className={addressStyle._item}>
                                    <p className={addressStyle._item_user}>
                                        <span>{x.name}</span>
                                        <span>{x.telephone}</span>
                                    </p>
                                    <div className={addressStyle._item_det}>
                                        {x.isDefault == 1 && <span>默认</span>}
                                        <p>{x.addressText}</p>
                                    </div>
                                    <div>
                                        {
                                            x.isDefault != 1 && <Button type="text" className={addressStyle._item_btn} onClick={() => { setDefault(x.id!) }}>设为默认地址</Button>}
                                        <Button type="text" className={addressStyle._item_btn} onClick={() => handleClick(x.id!)}>编辑</Button>
                                        <Button type="text" className={addressStyle._item_btn} onClick={() => del(x.id!)}>删除</Button>
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
            </Card>
            <EditCom ref={EditComRef} updateList={getList} />
        </>
    )
};

export default AddressEle;