import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import type { ShopItem, AddCartParams, EditCartParams } from "@/models/cart.models";
import {
  addCart as addCartApi,
  editCart as editCartApi,
  removeCart as removeCartApi,
  getCartData as getCartDataApi,
} from "@/api/cart";
import { message } from "antd";

interface CartState {
  shops: ShopItem[];
  selectedAll: boolean;
  selectedCartId: string[];
}
const initialState: CartState = {
  shops: [],
  selectedAll: false,
  selectedCartId: [],
};
export const getCartDataAction = createAsyncThunk(
  "fetch/getCartData",
  async () => {
    const res = await getCartDataApi();
    return res.data;
  }
);

export const addCartAction = createAsyncThunk(
  "fetch/addCart",
  async (value: AddCartParams) => {
    const res = await addCartApi(value);
    return res.data;
  }
);

export const editCartAction = createAsyncThunk(
  "fetch/editCart",
  async (value: EditCartParams) => {
    const res = await editCartApi(value);
    return res.data;
  }
);

export const removeCartAction = createAsyncThunk(
  "fetch/removeCart",
  async (value: string[]) => {
    const res = await removeCartApi(value);
    return res.data;
  }
);

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    clearCartData(state) {
      state.shops = [];
    },
    setSelectedCartId(state, action: PayloadAction<string>) {
      const index = state.selectedCartId.findIndex(v => v === action.payload);
      let newList = [
        ...(state.selectedCartId || []),
      ];
      let isSelectedAll = true;
      if(index === -1) {
        newList.push((action.payload))
      } else {
        newList = newList.filter(v => v !== action.payload);
      }
      const findNoSelected = state.shops[0].goodsList.find(v => newList.indexOf(v.cartId) === -1);
      if(findNoSelected) {
        isSelectedAll = false;

      }
      state.selectedAll = isSelectedAll;
      state.selectedCartId = newList;
    },
    selectAll(state, action: PayloadAction<boolean>) {
      if(action.payload) {
        const allCartIds = state.shops[0].goodsList.map(
          (v) => v.cartId
        );
        state.selectedAll = true;
        state.selectedCartId = allCartIds;
      } else {
        state.selectedAll = false;
        state.selectedCartId = [];
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCartDataAction.fulfilled, (state, action) => {
      if (action.payload && action.payload.shops && action.payload.shops[0]) {
        //  清除已删除商品选中信息
        let selectedCartId = [
          ...(state.selectedCartId || [])
        ]
        if (!selectedCartId) {
          selectedCartId = [];
          state.selectedAll = false;
        } else {
          const allCartIds = action.payload.shops[0].goodsList.map(
            (v) => v.cartId
          );
          const newSelectedCartId = selectedCartId.filter(
            (v) => allCartIds.indexOf(v) != -1
          );
          state.selectedCartId = newSelectedCartId;
          if (
            newSelectedCartId.length ===
            action.payload.shops[0].goodsList.length
          ) {
            state.selectedAll = true;
          } else {
            state.selectedAll = false;
          }
        }
        state.shops = action.payload.shops;
      } else {
        state.shops = [];
        state.selectedAll = false;
        state.selectedCartId = [];
      }
    });

    builder.addCase(addCartAction.fulfilled, (state, action) => {
      if (action.payload) message.success("已加入购物车");
    });

    builder.addCase(editCartAction.fulfilled, (state, action) => {
      // if (action.payload) ;
      getCartDataAction()
    });

    builder.addCase(removeCartAction.fulfilled, (state, action) => {
      // if (action.payload) ;
    });
  },
});

export const { clearCartData, setSelectedCartId, selectAll } = cartSlice.actions;

export default cartSlice.reducer;
