import { Image } from "antd";
import MapImg from "@/assets/images/public/map.jpg";
import "./contact.scss";
import BreadCrumb from "@/components/public/Breadcrumb";

const Page = () => {
  return (
    <div className="contact-container page-wrapper">
      <BreadCrumb
        routes={[
          {
            path: "/",
            title: "首页",
          },
          {
            // path: '/product-search',
            title: "联系我们",
          },
        ]}
      />
      <div className="page-main">
        <div className="action-title">联系我们</div>
        <div className="content">
          <div className="content-title">浙江江心调味食品有限公司</div>
          <div className="txt">
            <div className="label">地址：</div>
            <div className="value">浙江省永嘉县乌牛街道岭下工业区</div>
          </div>
          <div className="txt">
            <div className="label">邮编：</div>
            <div className="value">325100</div>
          </div>
          <div className="txt">
            <div className="label">公司电话：</div>
            <a className="value" href="tel:400-032-0070">400-032-0070</a>
          </div>
          <div className="txt">
            <div className="label">公司邮箱：</div>
            <a className="value" href="mailto:jx@jiangxin-food.com">jx@jiangxin-food.com</a>

          </div>
          {/* <div className="txt">
            <div className="label">公司传真：</div>
            <div className="value">021-52858888-650</div>

          </div> */}

        <div className="map-img">
          <Image className="i" preview={false} src={MapImg}></Image>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
