import React, {useState} from 'react';
const FilterItem: React.FC<{
  label: string;
  param: string;
  select: number;
  list: { text: string; value: string }[];
  setSelected?: (index:  number) => void;
}> = React.memo(({ label, list, select, setSelected, }) => {
    const [ expand, setExpand ] = useState(false);

    return (
      <div className="list-filter-item ">
        <div className="label">{label}</div>
        <div className="list-con">
          {list.slice(0, expand ? list.length : 7).map((v, i) => {
            return (
              <div key={i} className={`filter-txt ${i == select ? 'active' : ''}`}  onClick={() => {
                setSelected && setSelected(i)
              }}>
                <div className="txt">{v.text}</div>
              </div> 
            );
          })}
          
        </div>
        {
            list.length > 8 && <div className="filter-expand" onClick={() => {
                setExpand(!expand)
            }}>
                <div className="txt">{expand ? '收起' : '更多'}</div>
                <div className={`iconfont ${expand ? 'icon-arrow-top' : 'icon-arrow-bottom-copy'}`}></div>
            </div>
          }
      </div>
    );
  });

export default FilterItem;
