import React, { useState, useEffect } from "react";
import { Button, Modal, Image } from "antd";
// import videojs from "video.js";
import {CloseOutlined} from '@ant-design/icons'
// 记得引用css文件！
import "video.js/dist/video-js.css";
const App: React.FC<{
  show?: boolean;
  videoData: {
    cover: string;
    url: string;
  };
  onChange?: (val: boolean) => void;
}> = ({ show, onChange, videoData }) => {
  const [loading, setLoading] = useState(false);
  const closePopup = () => {
    // setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    //   setOpen(false);
    // }, 3000);
    videoRef.current.pause()
    console.log('videoRef.current', videoRef.current)
    onChange && onChange(false);
  };
  const videoRef = React.useRef<any>(null);
  // 播放器实例的引用Hook
  // const playerRef = React.useRef<any>(null);
  // const {options, onReady} = props;

  // useEffect(() => {
  //   // 确保video.js的播放器实例player仅被初始化一次，否则会报错
  //   if(!videoUrl) return;
  //   if (!playerRef.current) {
  //     const videoElement = videoRef.current;
  //     if (!videoElement) {
  //       return;
  //     }

  //     const player = (playerRef.current = videojs(
  //       videoElement,
  //       {
  //         autoplay: true,
  //         // 播放器子控件是否显示：为true时显示暂停、播放速率等按钮
  //         controls: true,
  //         // 响应性：为true时，播放视频进度条会自动移动
  //         // responsive: true,
  //         // 流式布局：为true时尽可能大得填满父标签的剩余空间
  //         fluid: true,
  //         // 视频源
  //         sources: [
  //           {
  //             // 视频文件的路径，可以是一个前端相对路径、后台视频文件URL、直播源等
  //             src: videoUrl,
  //             // 视频源类型
  //             type: "video/mp4",
  //           },
  //         ],
  //       },
  //       () => {
  //         videojs.log("播放器准备就绪！");
  //         // onReady && onReady(player);
  //       }
  //     ));
  //     // 当props发生变化时，可以对已经存在的player实例作一些操作，如：
  //   } else {
  //     // const player = playerRef.current;
  //     // player.autoplay(options.autoplay);
  //     // player.src(options.sources);
  //   }
  // }, [videoUrl]);
  // useEffect(() => {
  //   const player = playerRef.current;

  //   return () => {
  //     if (player) {
  //       player.dispose();
  //       playerRef.current = null;
  //     }
  //   };
  // }, [playerRef]);
  useEffect(() => {

  }, [])
  return (
    <>
      <Modal
        open={show}
        onOk={closePopup}
        onCancel={closePopup}
        footer={false}
        style={{
          padding:'0'
        }}
        wrapClassName="video-popup"
        width={800}
        closeIcon={<CloseOutlined style={{
          color:'#fff',
          marginTop: '-20px',
          marginRight: '-20px'
        }}/>}
      >
        <div
          className="popup-con"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "0 0px",
            textAlign: "center",
            paddingTop: "30px",
          }}
        >
          <div className="video-con">
            {
              <video ref={videoRef} className="video" style={{
                width:'100%',
  
              }}  autoPlay controls poster={videoData.cover} src={videoData.url}></video>
            }
            {/* <video ref={videoRef} className="custom-video-js video-js vjs-big-play-centered" /> */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default App;
