import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { CategoryItem } from '@/models/product.models';
interface CategoryState {
  value: CategoryItem[]
}

const initialState: CategoryState = {
  value: [],
}

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategory: (state, action: PayloadAction<CategoryItem[]>) => {
      state.value = action.payload;
    },
  },
})

export const { setCategory } = categorySlice.actions

export default categorySlice.reducer
