import React, { useCallback, useMemo } from "react";
import type { MenuProps } from "antd";
import { Popover, Badge, Image, Empty } from "antd";

import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { setShowLoginPopup } from "@/store/app/system";
import { PrimaryColor } from "@/models/constants";
import { getResourceUrl } from "@/utils/tool";

const PopContent = () => {
  const cartData = useAppSelector((state) => state.cart.shops);
  const navigate = useNavigate();
  const totalCount = useMemo(() => {
    let count = 0;
    if(!cartData || !cartData[0]) return;
    cartData.forEach((shop) => {
      shop.goodsList.forEach((goods) => {
        count += parseInt(goods.num);
      });
    });
    return count;
  }, [cartData]);
  return (
    <div className="common-cart-popup-con">
      <div className="cart-list">
        {cartData &&
          cartData.length > 0 &&
          cartData[0] &&
          cartData[0].goodsList.map((goods, gI) => {
            return (
              <div
                className="cart-item"
                key={gI}
                onClick={() => {
                  navigate(`/product-detail/${goods.itemId}`);
                }}
              >
                <div className="img-con">
                  <Image
                    preview={false}
                    src={getResourceUrl(goods.goodsImg)}
                  ></Image>
                </div>
                <div className="info">
                  <div className="name">{goods.goodsName}</div>
                  <div className="quantity">x{goods.num}</div>
                </div>
                <div className="price">
                  <span className="sub">¥</span>
                  <span className="v">{goods.price}</span>
                </div>
              </div>
            );
          })}
        {cartData && cartData.length == 0 && (
          <div className="empty-con">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="购物车中还没有商品"
            ></Empty>
          </div>
        )}
      </div>
      <div className="cart-footer">
        <div className="cart-count">
          <div className="left">
            <span className="mc">{totalCount || 0}</span>件商品
          </div>
          <div className="right">
            <span className="label">总计</span>
            <div className="price">
              <span className="sub">¥</span>
              <span className="v">
                {cartData[0] && cartData[0].goodsAmount
                  ? cartData[0].goodsAmount
                  : "0.00"}
              </span>
            </div>
          </div>
        </div>
        <div className="primary-button" onClick={() => {
          navigate('/cart');
        }}>查看购物车</div>
      </div>
    </div>
  );
};

const CartPopOver: React.FC = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const sessionId = useAppSelector((state) => state.user.value.sessionId);
  const cartData = useAppSelector((state) => state.cart.shops);
  const totalCount = useMemo(() => {
    let count = 0;

    if(!cartData || cartData.length == 0) return count;
    cartData.forEach((shop) => {
      shop.goodsList.forEach((goods) => {
        count += parseInt(goods.num);
      });
    });
    return count;
  }, [cartData]);
  return (
    <div className="icon-item">
      <Badge count={totalCount} style={{ backgroundColor: PrimaryColor }}>
        {
          sessionId && <Popover content={PopContent} placement="bottomRight">
          <div className="iconfont icon-gouwuche" onClick={() => {
             navigate('/cart');
          }}></div>
        </Popover>
        }
        {
          !sessionId && 
          <div className="iconfont icon-gouwuche" onClick={() => {
            console.log('setShowLoginPopup')
            dispatch(setShowLoginPopup(true))
          }}></div>
       
        }
      </Badge>
    </div>
  );
};
export default CartPopOver;
