import { App, ConfigProvider } from "antd";
import zhCN from "antd/locale/zh_CN";
import "dayjs/locale/zh-cn";

import Router from "@/router/index";
import { store, persistor } from "./store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "@/assets/style/init.scss";

import LoginPopup from "@/components/user/login";
import InitApp from "@/components/public/InitApp";
import { AliveScope } from "react-activation";

function MyApp() {

    return (
        <AliveScope>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <InitApp>
                        <ConfigProvider
                            locale={zhCN}
                            theme={{
                                token: {
                                    colorPrimary: "#EC6D2A",
                                    borderRadius: 4,
                                },
                            }}
                        >
                            <div className="App">
                                <Router />
                                <LoginPopup />
                            </div>
                        </ConfigProvider>
                    </InitApp>
                </PersistGate>
            </Provider>
        </AliveScope>
    );
}

export default ()=>(
    <App>
        <MyApp/>
    </App>
);
