import {useState} from 'react';
import { Image } from "antd";
import AboutImg from "@/assets/images/public/info.jpg";
import "./about.scss";
import BreadCrumb from "@/components/public/Breadcrumb";
import { getResourceUrl } from "@/utils/tool";
import VideoPopup from './components/video-popup'
import {PlayCircleOutlined} from '@ant-design/icons'

const baseUrl = 'https://pic.flyco.net.cn/jx/advertising/'
const advertisingData = [
  {
    title: "江心双缸酱油",
    subtitle: "100%纯粮酿造 烹尽天下美味",
    video: baseUrl + "双缸酱油.mp4",
    cover: baseUrl + "双缸酱油-cover.jpeg",
  },
  {
    title: "江心花椒米醋",
    subtitle: "椒香酸爽 醇香绵润",
    video: baseUrl + "花椒米醋.mp4",
    cover: baseUrl + "花椒米醋-cover.jpeg",
  },
  {
    title: "江心酱油醋",
    subtitle: "蘸拌绝配 一步到味",
    video: baseUrl + "酱油醋.mp4",
    cover: baseUrl + "酱油醋-cover.jpeg",
  },
  {
    title: "江心原酿黄酒",
    subtitle: "古方典酿 醇香美味",
    video: baseUrl + "原酿黄酒.mp4",
    cover: baseUrl + "原酿黄酒-cover.jpeg",
  },
];
const Page = () => {
  const [showVideoPlay, setShowVideoPlay] = useState(false);
  const [playVideo, setPlayVideo] = useState({
    cover: '',
    url:''
  });
  return (
    <div className="about-container page-wrapper">
      <BreadCrumb
        routes={[
          {
            path: "/",
            title: "首页",
          },
          {
            // path: '/product-search',
            title: "关于我们",
          },
        ]}
      />
      <div className="page-main">
        <div className="card-item">
          <div className="card-title">产品视频</div>
          <div className="card-content video-list">
            {advertisingData.map((item, index) => {
              return (
                <div className="video-item" key={index} onClick={() => {
                  setPlayVideo({
                    url:(item.video || '') || '',
                    cover: (item.cover || '') || ''
                  });
                  setShowVideoPlay(true)
                }}>
                  <div className="img">

                    <img src={(item.cover)} alt="" />
                    <div className="play-icon">
                    <PlayCircleOutlined className='icon'/>
                    </div>
                  </div>
                  <div className="info">
                    <div className="title">{item.title}</div>
                    <div className="desc">{item.subtitle}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="card-item">
          <div className="card-title">品牌故事</div>
          <div className="card-content about">
            <div className="img">
              <Image className="i" src={AboutImg} preview={false}></Image>
            </div>
            <div className="about-info">
              <h1 className="title">浙江江心调味食品有限公司</h1>
              <div className="txt">
                ”江心味业”前身为“温州酿造厂”，历史溯源于清朝道光计年(1841年)广和、长春酱园，距今已有170多年历史。“江心味业”本着振兴发扬百年酱园品牌的责任，焕新出发，立足温州迈向全国市场。
              </div>
              <div className="txt">
                公司秉承传扬江南传统酿造工艺，传递全新生活方式的理念，铭记“酱人匠心、一生一事”的企业文化。公司致力以品质成就全球调味品第一品牌的愿景，让酿造美味“味你陪伴一生”。
              </div>
            </div>
          </div>
        </div>
      </div>
      <VideoPopup onChange={(e) => {
        setShowVideoPlay(e)
      }} show={showVideoPlay} videoData={playVideo}/>
    </div>
  );
};

export default Page;
