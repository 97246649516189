import React, { useCallback, useState } from "react";
import { useAppSelector } from "@/store/hooks";

const ListSort:React.FC<{setFilterData?: (p: {[K: string]: any}) => void}> = React.memo(({ setFilterData }) => {
  
  const [sortType, setSortType] = useState(''); // price order asc/desc 
  const changeSortType = useCallback((type: string) => {
    setSortType(type);
    const newFilterData = {
      orderBy:'',
      order: 'desc'
    };
    if(type.indexOf('price') == 0) {
      newFilterData.orderBy = 'minprice';
      newFilterData.order = type == 'price_desc' ? 'desc' : 'asc';
    } else {
      newFilterData.orderBy = type;
    }
    setFilterData && setFilterData(newFilterData)
  }, [])
  return <div className="list-sort-con page-wrapper">
    <div className="label">排序</div>
    <div className="list">
      <div className={`item ${sortType === '' ? 'active' : ''}`} onClick={() => {changeSortType('')}}><div className="txt">综合</div></div>
      <div className={`item ${sortType === 'salesvolume' ? 'active' : ''}`} onClick={() => {changeSortType('salesvolume')}}><div className="txt">销量</div></div>
      <div className={`item ${sortType === 'uploadtime' ? 'active' : ''}`} onClick={() => {changeSortType('uploadtime')}}><div className="txt">新品</div></div>
      <div className={`item ${sortType.indexOf('price') === 0 ? 'active' : ''}  ${sortType === 'price_desc' ? 'desc' : ''} ${sortType === 'price_asc' ? 'asc' : ''}`} onClick={() => {
        let newType = sortType.indexOf('price') == -1 ? 'price_desc' : (sortType == 'price_desc' ? 'price_asc' : 'price_desc');
        changeSortType(newType)
      }}><div className="txt">价格</div> 
        <div className="arrow-con">
          <div className="arrow-top"></div>
          <div className="arrow-bottom"></div>
        </div>
        </div>
    </div>
  </div>;
});

export default ListSort;
